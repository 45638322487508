import { Component, ContentChild, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Swiper } from 'swiper/types';
import * as _ from 'lodash';
import { SwiperContentDirective } from './directives/swiper-content.directive';
import { LanguageService } from '@app/services/language.service';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss']
})
export class SwiperComponent {

  @ContentChild(SwiperContentDirective) swiperContent!: SwiperContentDirective;

  @ViewChild('buttonPrev') buttonPrev: ElementRef;
  @ViewChild('buttonNext') buttonNext: ElementRef;
  @ViewChild('swiperContainerRef') set swiperContainerRef(ref: ElementRef) {
    this.initSwiper(ref)
  };

  @Input() slides: any[] = [];
  @Input() set options(options: any) {
    this._options = _.merge({}, this._options, options);
  }
  get options(): any {
    return this._options;
  }

  public swiper: Swiper;

  private _options = {
    spaceBetween: 20,
    touchAngle: 75,
    navigation: {
      enabled: false,
      navigationDisabledClass: 'swiper-navigation-disabled'
    }
  };

  constructor(
    private readonly languageService: LanguageService
  ) { }

  private initSwiper(ref: ElementRef): void {
    if (!ref) {
      return;
    }

    this.swiper = ref.nativeElement.swiper;
    this.swiper.params = Object.assign(this.swiper.params, this.options);

    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.swiper.changeLanguageDirection('rtl');
    } 
  }

}
