import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CarouselComponent } from '@app/components/commons/carousel/carousel.component';
import { ProfileService } from 'library-explorer';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ClassDTO } from 'src/app/model/classDTO.model';
import { CourseDTO } from 'src/app/model/courseDTO.model';
import { PromotionBlock } from 'src/app/model/promotion-block';
import { ClassService } from 'src/app/services/api/class.service';
import { CourseService } from 'src/app/services/api/course.service';

import { NavigateService } from 'src/app/services/navigate.service';

@Component({
  selector: 'app-promotion-block',
  templateUrl: './promotion-block.component.html',
  styleUrls: ['./promotion-block.component.scss']
})
export class PromotionBlockComponent implements OnInit, OnDestroy {

  @ViewChild(CarouselComponent) carouselComponent: CarouselComponent;
  @Input() public block: PromotionBlock;

  public sliderCourses: CourseDTO[] = [];
  public selectedItemId = '';
  public userName: string;

  public sliderCoursesControl: FormControl;
  public classList: ClassDTO[] = [];
  public showPromotionVideo = false;
  public hasLeftColumn = false;

  private unsubscribe: Subject<void> = new Subject();
  public courseCarouselConfig;

  constructor(
    private readonly renderer: Renderer2,
    private readonly courseService: CourseService,
    private readonly classService: ClassService,
    private readonly profileService: ProfileService,
    private readonly navigateService: NavigateService
  ) { }

  ngOnInit() {
    this.userName = this.profileService.getCurrentProfileValue()?.name;
    this.hasLeftColumn = !!(this.block.title || this.block.mediaVideo?.uri || this.block.link?.uri || this.block.embedVideo);
    this.createControl();
    this.getClasses();

    this.courseCarouselConfig = {
      navigation: {
        enabled: false
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          navigation: {
            enabled: true,
            wide: true
          },
        },
        640: {
          slidesPerView: 2,
          navigation: {
            enabled: true,
            wide: true
          },
        },
        992: {
          slidesPerView: 2,
          navigation: {
            enabled: false
          }
        },
        1200: {
          slidesPerView: this.hasLeftColumn ? 2 : 3,
          navigation: {
            enabled: false
          }
        },
        1661: {
          slidesPerView: 3,
          navigation: {
            enabled: false
          }
        }
      }
    };
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public playPromotionVideo() {
    const promotionLink = this.block.link && this.block.link.uri;
    if (promotionLink) {
      this.navigateService.navigate(promotionLink);
    } else {
      this.showPromotionVideo = true;
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public closeVideoOverlay(): void {
    this.showPromotionVideo = false;
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  private createControl() {
    this.sliderCoursesControl = new FormControl('');
    this.sliderCoursesControl.valueChanges
      .pipe(
        switchMap((id: string) => this.getCourses(id)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private getCourses(id: string = null): Observable<unknown> {
    return this.courseService.getAllCourses(id)
      .pipe(
        tap((courses: CourseDTO[]) => this.sliderCourses = courses),
        takeUntil(this.unsubscribe)
      );
  }

  private getClasses() {
    const params = new HttpParams()
      .set('sort', 'weight:ASC,progress');

    this.classService.getAllClasses(params)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((classes: ClassDTO[]) => {
        this.classList = classes;
        const activeClassId = classes.length ? classes[0].id : null;
        this.sliderCoursesControl.patchValue(activeClassId);
      });
  }
}
