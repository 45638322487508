import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BundleType, BUNDLE_TYPE_TEXT_MAP } from 'library-explorer';
import { NavigateFrom } from '@app/model/enums/navigate-from.enum';
import { LearningPathModel } from '@app/model/learning-path.model';
import { LearningPathService } from '@app/services/api/learning-path.service';
import { Subject } from 'rxjs';
import { Swiper } from 'swiper/types';
import { SwiperComponent } from '@app/shared/components/swiper/swiper.component';

@Component({
  selector: 'app-learning-path-block',
  templateUrl: './learning-path-block.component.html',
  styleUrls: ['./learning-path-block.component.scss']
})
export class LearningPathBlockComponent implements OnInit, OnDestroy {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent

  @Input() block: any;
  
  public learningPathList: LearningPathModel[] = [];
  public activeLearningPaths = 0;
  public completedLearningPaths = 0;
  public swiperContainer: Swiper;

  public BundleType = BundleType;
  public NavigateFrom = NavigateFrom;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    @Inject(BUNDLE_TYPE_TEXT_MAP) public bundleTypeTextMap: any,
    private readonly learningPathService: LearningPathService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.learningPathList = this.block.contents.map(this.learningPathService.processLearningPath);
    this.activeLearningPaths = this.learningPathList.reduce((prev, item) => prev += item.progress !== 100 ? 1 : 0, 0)
    this.completedLearningPaths = this.learningPathList.reduce((prev, item) => prev += item.progress === 100 ? 1 : 0, 0)
  }

  public navigateToLearningPath(learningPath: LearningPathModel, event: MouseEvent): void {
    this.router.navigate(['/learning-path', learningPath.uuid]);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
