import { Injectable, Injector } from '@angular/core';
import { NotificationDeliveryType } from '@app/model/enums/notification-delivery-type.enum';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { StorageMap } from "@ngx-pwa/local-storage";
import { HttpService, storageKeys } from 'library-explorer';
import { from, of } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { NavigateService } from './navigate.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private httpService: HttpService,
    private storage: StorageMap,
    private navigateService: NavigateService,
    private injector: Injector) {
  }

  async addNotificationListeners() {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    await PushNotifications.addListener('registration', token => {
      if (token) this.storeDeviceToken(token.value);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', actionPerformed => this.handlePushNotificationAction(actionPerformed));
  }

  async registerNotifications() {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      return;
    }

    await PushNotifications.register();
  }

  clearPushNotificationResources(): Promise<void> {
    return new Promise((resolve, _reject) => {
      this.storage.get(storageKeys.DEVICE_RESOURCE_ID).pipe(
        mergeMap((deviceId: string) => {
          if (!deviceId) {
            return of(null);
          }
          return this.httpService.deleteCore(`/devices/${deviceId}`).pipe(
            mergeMap(() => from(this.unregisterPushNotifications())),
            mergeMap(() => this.storage.delete(storageKeys.DEVICE_RESOURCE_ID))
          );
        }),
        finalize(() => resolve())
      ).subscribe();
    });
  }

  private async unregisterPushNotifications(): Promise<void> {
    await PushNotifications.unregister();
    await PushNotifications.removeAllListeners();
  }

  storeDeviceToken(token: string) {
    this.httpService.postCore(`/devices`, { deviceToken: token }).pipe(
      mergeMap((data: { id: string }) => this.storage.set(storageKeys.DEVICE_RESOURCE_ID, data.id))
    ).subscribe();
  }

  private isNativePlatformAndPluginAvailable(): boolean {
    return Capacitor.isNativePlatform() &&
      Capacitor.getPlatform() === 'ios' &&
      Capacitor.isPluginAvailable('PushNotifications')
  }

  private async handlePushNotificationAction(actionPerformed: ActionPerformed): Promise<void> {
    try {
      const pushNotificationData = actionPerformed.notification.data || {};

      const notificationService = this.injector.get(NotificationService);

      notificationService.waitForNotificationsLoaded().subscribe(() => {
        notificationService.markNotificationAsSeen({ notificationId: pushNotificationData.notification.id, ...pushNotificationData.notification }, NotificationDeliveryType.PUSH);
      });

      // Navigate to the appropriate route
      this.navigateService.navigateByNavigationRouteLink(pushNotificationData.link);
    } catch { }
  }
}
