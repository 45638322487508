import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HydraResponseDto } from '@app/model/hydra-response-dto';
import { NotificationDelivery } from '@app/model/notification-delivery';
import { SessionStorageService } from 'library-explorer';
import { environment } from 'src/environments/environment';
import { NotificationDeliveryType } from '@app/model/enums/notification-delivery-type.enum';
import { interceptorsToken } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {
  private readonly apiUrl = environment.apiCoreAppUrl;

  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly httpClient: HttpClient) { }

  public getNotifications(page = 1): Observable<HydraResponseDto<NotificationDelivery>> {
    const params = new HttpParams().set('page', page.toString());

    return this.httpClient.get<HydraResponseDto<NotificationDelivery>>(`${this.apiUrl}api/notification-deliveries`, {
      headers: this.buildHeaders(), params
    });
  }

  public markNotificationAsSeen(id: string, deliveryType: NotificationDeliveryType): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}api/notifications/${id}/see`, { deliveryType }, { headers: this.buildHeaders() });
  }

  private getTokenHeader(httpHeaders: HttpHeaders): HttpHeaders {
    const token = this.sessionStorageService.getJwtTokenValue();
    if (token) {
      httpHeaders = httpHeaders.append('Authorization', `Bearer ${token}`);
    }
    return httpHeaders;
  }

  private buildHeaders(httpHeaders = new HttpHeaders()): HttpHeaders {
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    httpHeaders = this.getTokenHeader(httpHeaders);
    return httpHeaders;
  }
}
