<div class="d-block col-12 px-0 w-100 carousel-wrapper" [ngClass]="{'carousel__custom-arrow': type==='promotion-course'}"
     *ngIf="items && items.length">
  <app-swiper [slides]="items" [ngClass]="type" [options]="config">
    <ng-template swiperContent let-item="item">
      <ng-container [ngSwitch]="type">
        <app-dashboard-item
          *ngSwitchCase="CarouselType.DASHBOARD_ITEM"
          [item]="item"
          [progressShow]="true"
          [startButton]="item.isNextRecommended"
          [paymentEnabled]="isPaymentEnabled"
          [inCarousel]="true"
          (click)="navigate(item, $event)">
        </app-dashboard-item>

        <app-event-card  
          *ngSwitchCase="CarouselType.WEBINAR"
          [item]="item"
          [disabled]="true"
          class="event-card" 
          (click)="navigate(item, $event)">
        </app-event-card>

        <lib-course-card
          *ngSwitchDefault
          [data]="item"
          [paymentEnabled]="isPaymentEnabled"
          [showDetailsButton]="courseDetailsEnabled"
          [extended]="extendedCard"
          (cardClick)="navigate(item, $event)"
          (buttonClick)="navigate(item, $event)"
          (detailsClick)="openDetails(item)">
        </lib-course-card>
      </ng-container>
    </ng-template>
  </app-swiper>
</div>
