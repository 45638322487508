import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationApiService } from 'src/app/services/api/notification-api.service';
import { finalize } from 'rxjs/operators';
import { NotificationDelivery } from '@app/model/notification-delivery';
import { SettingsService } from '@app/services/settings.service';


@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  @Input() public isDialog = false;
  @Output() public notificationsExist: EventEmitter<boolean> = new EventEmitter();
  @HostBinding('attr.style') public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--color: ${this.color}`);
  }

  public isLoading = false;
  public color: string;
  public notifications: (NotificationDelivery & { seen?: boolean, image?: string })[] = [];
  public totalNotifications = 0;

  private currentLoadedPage = 1;

  constructor(
    private readonly notificationApiService: NotificationApiService,
    private readonly notificationService: NotificationService,
    private readonly settingsService: SettingsService,
    private readonly sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.color = this.settingsService.primaryColor;
    this.loadNotifications();
  }

  public openNotificationPopup(notification: NotificationDelivery & { seen?: boolean, image?: string }): void {
    notification.seen = true;
   // this.notificationService.openNotificationPopup(notification);
  }

  public loadMoreNotifications(): void {
    this.currentLoadedPage += 1;
    this.isLoading = true;

    this.notificationApiService.getNotifications(this.currentLoadedPage)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe((data) => {
        this.totalNotifications = data['hydra:totalItems'];
        let newNotifications = data['hydra:member'];
        newNotifications = newNotifications.filter(item => {
          return !this.notifications.some(notification => notification.notificationId === item.notificationId);
        });
        const items = this.mapNotifications(newNotifications);
        this.notifications.push(...items);
      });
  }

  private loadNotifications(): void {
    this.isLoading = true;

    if (this.isDialog) {
      this.notificationService.notifications
        .subscribe(data => {
          this.notifications = this.mapNotifications([...data]);
          this.notificationsExist.emit(data.length !== 0);
          this.isLoading = false;
        });
    } else {
      this.notificationApiService.getNotifications()
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe((data) => {
          this.totalNotifications = data['hydra:totalItems'];
          this.notifications = this.mapNotifications(data['hydra:member']);
        });

      this.notificationService.notifications
        .subscribe(data => {
          const newNotifications = data.filter(item => {
            return !this.notifications.some(notification => notification.notificationId === item.notificationId);
          });
          const items = this.mapNotifications(newNotifications);
          this.notifications.unshift(...items);
        });
    }
  }

  private mapNotifications(data: NotificationDelivery[]): (NotificationDelivery & { seen?: boolean, image?: string })[] {
    return data.map(item => ({
      ...item,
      seen: !!(item.emailSeenAt || item.popupNotificationSeenAt || item.regularNotificationSeenAt)
    }));
  }

}
