import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'lib-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent implements OnInit {
  @Input() public url: string;
  @Input() public size = 24;

  @Output() loadFailed: EventEmitter<void> = new EventEmitter();

  public svgIcon: SafeHtml;

  constructor(
    private readonly cdf: ChangeDetectorRef,
    private readonly httpClient: HttpClient,
    private readonly sanitizer: DomSanitizer,
    ) {
  }

  ngOnInit(): void {
    this.httpClient.get(this.url, { responseType: 'text' }) 
      .pipe(
        catchError(error => {
          this.loadFailed.emit();
          throw error;
        })
      )
      .subscribe(value => {
        this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
        this.cdf.markForCheck();
      });
  }

}
