import { Component, Input, ViewChild } from '@angular/core';
import { SwiperComponent } from '@app/shared/components/swiper/swiper.component';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  @Input() block: any;

  public swiperOptions = {
    centeredSlides: true,
    spaceBetween: 0,
    slidesPerView: 'auto',
    showOverflow: true
  };

  constructor() { }

}
