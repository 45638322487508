import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { SendbirdService } from '@app/services/sendbird.service';

import { fromEvent, Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ChatMode } from 'src/app/model/enums/chat-mode.enum';

@Component({
  selector: 'app-chat-list-dialog',
  templateUrl: './chat-list-dialog.component.html',
  styleUrls: ['./chat-list-dialog.component.scss']
})
export class ChatListDialogComponent implements OnInit, OnDestroy {
  public mode = ChatMode.DIALOG;

  public userHasChannels = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly elementRef:ElementRef,
    private readonly sendbirdService: SendbirdService) { }

  ngOnInit() {
    this.listenForOutsideClicks();
  }
  
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private listenForOutsideClicks(): void {
    timer(100)
      .pipe(
        switchMap(() => fromEvent(document, 'click')),
        takeUntil(this.unsubscribe),
        filter((event: MouseEvent) => {
          return !this.elementRef.nativeElement.contains(event.target);
        })
      )
      .subscribe(() => {
        this.closeDialog();
      });
  }

  public closeDialog(): void {
    this.sendbirdService.closeChatListDialog();
  }

  public channelsLoaded(hasChannels: boolean): void {
    this.userHasChannels = hasChannels;
  }

}
