import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseWarningModalComponent } from '@app/components/base-warning-modal/base-warning-modal.component';

import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BranchService } from 'src/app/services/branch.service';
import { MaintenanceService, MaintenanceType, interceptorsToken } from 'library-explorer';
import { environment } from '@env/environment';

@Injectable()
export class HttpErrorHandlingInterceptor implements HttpInterceptor {
  private readonly defaultErrorCode = 'ERROR.error_occurred_please_contact_us';

  public constructor(
    private readonly toastService: ToastrService,
    private readonly translateService: TranslateService,
    private readonly maintenanceService: MaintenanceService,
    private readonly dialog: MatDialog,
    private readonly branchService: BranchService,
    private readonly router: Router) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isDrupalApiRequest = request.url.indexOf(environment.apiUrl) !== -1;

    const skipToastNotificationsErrorHandlers = request.headers.get(interceptorsToken.SKIP_TOAST_ERROR_HANDLERS);
    const handlePermissionDeniedResponses = request.params.get(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM);
    const enabledMaintenanceInCaseServerError = request.params.get(interceptorsToken.ENABLE_MAINTENANCE_IN_CASE_SERVER_ERROR_PARAM);

    request = request.clone({
      headers: request.headers
        .delete(interceptorsToken.SKIP_TOAST_ERROR_HANDLERS),
      params: request.params
        .delete(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM)
        .delete(interceptorsToken.ENABLE_MAINTENANCE_IN_CASE_SERVER_ERROR_PARAM)
    });

    if (!isDrupalApiRequest) {
      return next.handle(request);
    }

    return next.handle(request)
      .pipe(
        catchError((err: any) => {
          Sentry.captureException(err.originalError || err);

          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 403:
                if (handlePermissionDeniedResponses) {
                  this.router.navigate(['403']);
                }

                break;
              case 404:
                if (request.url.indexOf('/api/settings') !== -1) {
                  this.router.navigate(['/no-instance'], { skipLocationChange: true });
                } else if (handlePermissionDeniedResponses) {
                  this.router.navigate(['404'], { queryParams: { originalUrl: location.pathname + location.search } });
                }

                break;
              case 406:
                this.dialog.open(BaseWarningModalComponent, {
                  data: {
                    message: 'BRANCH.branch_redirect_message',
                    okBtnText: 'BRANCH.switch_to_branch',
                    cancelBtnText: 'COMMON.cancel',
                    okBtnHandler: () => this.branchService.selectBranch(err.error.branchId),
                    cancelBtnHandler: () => this.router.navigate(['/'])
                  }
                });
                break;
              case 503:
                this.maintenanceService.enableMaintenanceMode(MaintenanceType.BASIC);     
                break;
              case 599:
                this.maintenanceService.enableMaintenanceMode(MaintenanceType.SOFT_MAINTENANCE);
                break;
              default:
                if (err.status >= 500 && enabledMaintenanceInCaseServerError) {
                  this.maintenanceService.enableMaintenanceMode(MaintenanceType.SOFT_MAINTENANCE);
                }

                if (err.status >= 400) {
                  if (skipToastNotificationsErrorHandlers) {
                    break;
                  }

                  this.showTranslatedToastNotification(err.error?.message, err.error?.message_parameters)
                }
            }
          }

          return throwError(err);
        })
      );
  }

  private showTranslatedToastNotification(translationKey: string, params: { [key: string]: string} = {}): void {
    if (!this.translateService.currentLang) {
      this.toastService.error('An error occurred, please contact us with the context of your steps');
      return;
    }

    const hasTranslationKey = translationKey && this.translateService.translations[this.translateService.currentLang][translationKey];
    const messageKey = hasTranslationKey ? translationKey : this.defaultErrorCode;
    const message = this.translateService.instant(messageKey, params);

    this.toastService.error(message);
  }
}
