<div class="content-highlight block-container" *ngIf="items && items.length" [class.has-background]="block.backgroundColor || block.mediaBackground" [style.color]="block.textColor">
  <div *ngIf="!swiperComponent?.swiper?.isBeginning || !swiperComponent?.swiper?.isEnd || title" class="content-highlight__header">
    <h4 class="content-highlight__title" [rtl]="block.langcode">{{title}}</h4>
    <div *ngIf="!swiperComponent?.swiper?.isBeginning || !swiperComponent?.swiper?.isEnd" class="content-highlight__arrows" [style.color]="block.textColor">
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="swiperComponent?.swiper?.isBeginning" (click)="swiperComponent?.swiper?.slidePrev()">
        <lib-icon icon="arrow-left"></lib-icon>
      </button>
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="swiperComponent?.swiper?.isEnd" (click)="swiperComponent?.swiper?.slideNext()">
        <lib-icon icon="arrow-right"></lib-icon>
      </button>
    </div>
  </div>

  <div class="col-12 px-0 w-100" [ngClass]="block.mobileCardStyle">
    <ng-container *ngIf="block.sliderEnabled; else listView">
      <app-swiper [slides]="items" [options]="swiperOptions">
        <ng-template swiperContent let-item="item">
          <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
        </ng-template>
      </app-swiper>
    </ng-container>
  </div>
</div>

<ng-template #listView>
  <div class="content-highlight__list-view">
    <div class="content-highlight__row cards-base-grid">
      <div *ngFor="let item of items" class="content-highlight__slide">
        <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-item="item" #slideTmp>
  <lib-small-card
    *ngIf="block.mobileCardStyle === mobileCardStyleTypes.SMALL_CARD"
    [data]="item"
    (cardClick)="navigate($event, item)">
  </lib-small-card>
  <ng-container [ngSwitch]="item.bundle">
    <lib-class-card
      *ngSwitchCase="bundleTypes.CLASS"
      [data]="item"
      [orientation]="imageOrientation"
      (cardClick)="navigate($event, item)">
    </lib-class-card>
    <lib-course-card
      *ngSwitchCase="bundleTypes.COURSE"
      [data]="item"
		  [showDetailsButton]="courseDetailsEnabled"
      [paymentEnabled]="isPaymentEnabled"
      (cardClick)="navigate($event, item)"
      (buttonClick)="navigate($event, item)"
      (detailsClick)="openDetails(item)">
    </lib-course-card>
    <lib-course-card
      *ngSwitchCase="bundleTypes.MODULE"
      [data]="item"
      (cardClick)="navigate($event, item)"
      (buttonClick)="navigate($event, item)">
    </lib-course-card>
    <lib-lesson-card
      *ngSwitchDefault
      [data]="item"
      (cardClick)="navigate($event, item)">
    </lib-lesson-card>
  </ng-container>
</ng-template>
