import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FieldName } from '../../../common/models/enums/field-name.enum';
import { BUNDLE_TYPE_TEXT_MAP, SETTINGS_SERVICE } from '../../../common/constants/injection-tokens';
import { BaseSettingsGetService } from '../../../common/services/base-settings.service';
import { CourseCardImageRatioEnum } from '../../../common/models/enums/course-card-image-ratio.enum';
import { LeaderboardTypeEnum } from '../../../common/models/enums/settings-leaderboard-type.enum';
import { BundleType } from '../../../common/models/enums/bundle-type.enum';

@Component({
  selector: 'lib-base-card',
  template: '',
  styles: ['']
})
export class BaseCardComponent implements OnInit {

  @Input() data: any;
  @Input() showType = false;

  @Output() cardClick: EventEmitter<Event> = new EventEmitter();

  public certificationsTitle: string[];
  public achievementsTitle: string[];
  public selectiveLockingItems: string[];
  public hideProgress: boolean;
  public courseImageRatio: CourseCardImageRatioEnum = CourseCardImageRatioEnum.RATIO_16_9;
  public skipModule = false;
  public leaderboardEnabled = false;

  public readonly fieldNames: typeof FieldName = FieldName;
  public readonly bundleTypes: typeof BundleType = BundleType;

  constructor(
    @Inject(BUNDLE_TYPE_TEXT_MAP) public bundleTypeTextMap: any,
    @Inject(SETTINGS_SERVICE) private readonly settingsService: BaseSettingsGetService,
  ) { }

  ngOnInit(): void {
    this.certificationsTitle = this.data?.certifications?.map(item => item.title).join(', ');
    this.selectiveLockingItems = this.data?.selectiveLockingItems?.map(item => item.title).join(', ');
    this.achievementsTitle = this.data?.achievements?.map(item => item.title).join(', ');

    this.settingsService.getSettings().subscribe(settings => {
      this.hideProgress = this.data?.isLocked || settings.lesson && settings.lesson.hideProgressIndicators;
      this.courseImageRatio = settings.course?.cardImageRatio;
      this.skipModule = settings.system.skip_module;
      this.leaderboardEnabled = settings.leaderboard.type !== LeaderboardTypeEnum.DISABLED;
    })
  }
}
