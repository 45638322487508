import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-html-content-injector',
  templateUrl: './html-content-injector.component.html',
  styleUrls: ['./html-content-injector.component.scss']
})
export class HtmlContentInjectorComponent implements OnInit {
  @Input() public content: string;

  @ViewChild('contentContainer') set contentContainer(value: ElementRef<HTMLElement>) {
    this.injectHtmlAndLoadScriptsSequentially(value?.nativeElement);
  }

  private additionalTextScriptsLoaded = false;

  constructor(private readonly renderer: Renderer2) { }

  ngOnInit(): void {
  }

  private injectHtmlAndLoadScriptsSequentially(container: HTMLElement): void {
    if (this.additionalTextScriptsLoaded) {
      return;
    }

    if (!container || !this.content) {
      return;
    }

    container.innerHTML = this.content;
    const scripts = Array.from(container.querySelectorAll('script'));

    this.additionalTextScriptsLoaded = true;
    this.loadScriptsSequentially(scripts, container).then();
  }

  private loadScriptsSequentially(scripts: HTMLScriptElement[], container: HTMLElement): Promise<void> {
    return scripts.reduce((prevPromise, currentScript) => {
      return prevPromise.then(() => this.loadScript(currentScript, container));
    }, Promise.resolve());
  }

  private loadScript(script: HTMLScriptElement, container: HTMLElement): Promise<void> {
    return new Promise((resolve) => {
      const newScript = this.renderer.createElement('script');
      newScript.type = 'text/javascript';

      if (script.src) {
        newScript.src = script.src;
        newScript.onload = () => {
          resolve();
        };
      } else {
        newScript.text = script.innerHTML;
        resolve();
      }

      this.renderer.appendChild(container, newScript);
    });
  }
}
