import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BundleType, SettingsCourseDetailsScreenOptions } from 'library-explorer';
import { CarouselType } from 'src/app/model/enums/carousel-type.enum';
import { NodeModel } from 'library-explorer';
import { PayableNodeNavigationService } from 'src/app/services/payable-node-navigation.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SwiperComponent } from '@app/shared/components/swiper/swiper.component';
import { SettingsService } from '@app/services/settings.service';
import { AuthService } from '@app/services/auth.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  @Input() extendedCard: boolean;
  @Input() type: CarouselType = CarouselType.CLASS;
  @Input() public config = {
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      }
    }
  }
  @Input('items') items: any[];

  public CarouselType = CarouselType;
  public isPaymentEnabled = false;
  public courseDetailsEnabled = false;

  constructor(
    private readonly payableNodeNavigationService: PayableNodeNavigationService,
    private readonly paymentService: PaymentService,
    private readonly router: Router,
    private readonly settingsService: SettingsService,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.paymentService.getPaymentConfig().subscribe(res => {
      this.isPaymentEnabled = !!res.enabled;
    });

    combineLatest([
      this.settingsService.getSettings(),
      this.authService.isAuthorized()
    ]).subscribe(([settings, isLoggedIn]) => {
      this.courseDetailsEnabled = settings.course.courseDetailsScreen === SettingsCourseDetailsScreenOptions.ENABLED_FOR_ALL_USERS
        || (settings.course.courseDetailsScreen === SettingsCourseDetailsScreenOptions.ENABLED_FOR_LOGGED_IN_USERS && isLoggedIn);
    });
  }

  public navigate(item: NodeModel, event: any): void {
    switch (item.bundle) {
      case BundleType.WEBINAR:
      this.navigateToWebinar(item, event);
        break;
      default:
        this.payableNodeNavigationService.navigate(item, event);
    }
  }

  public openDetails(item: any): void {
    this.payableNodeNavigationService.openCourseDetails(item, true).subscribe();
  }

  private navigateToWebinar(item: NodeModel, event: any): void {
    this.router.navigate(['/webinars', item.uuid]);
  }
}
