import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebinarAttendee } from 'src/app/model/webinar-attendee.model';
import { WebinarSeriesModel } from 'src/app/model/webinar-series.model';
import { WebinarConnectionDataDTO } from 'src/app/model/webinarConnectionDataDTO';
import { WebinarDTO } from 'library-explorer';
import { HttpService, interceptorsToken } from 'library-explorer';
import { DataResponse } from '@app/model/data-response.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebinarsService {

  constructor(private httpService: HttpService) { }

  public getAllWebinars(extraParams?: HttpParams): Observable<{ items: WebinarDTO[], _meta?: any}> {
    let params = extraParams || new HttpParams();
    params = params
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<{ items: WebinarDTO[]}>('webinars', params);
  }

  public getWebinars(extraParams?: HttpParams): Observable<{ items: WebinarDTO[], _meta?: any}> {
    let params = extraParams || new HttpParams();

    params = params
      .set('active', 'true')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<{ items: WebinarDTO[]}>('webinars', params);
  }

  public getWebinarById(id: string): Observable<WebinarDTO> {
    const params = new HttpParams()
      .set('id', id)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<WebinarDTO>(`node`, params);
  }

  public getWebinarByAlias(alias: string): Observable<WebinarDTO> {
    const params = new HttpParams()
      .set('alias', alias)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<WebinarDTO>(`node`, params);
  }

  public getWebinarConnectionData(meetingId: number, role: number = 0): Observable<WebinarConnectionDataDTO> {
    const body = { meetingId, role };

    return this.httpService.post<WebinarConnectionDataDTO>('zoom-api/connection-data', body);
  }

  public addUserToWebinar(webinarId: string, userId: string): Observable<void> {
    const data = {
      webinar_id: webinarId,
      user_id: userId
    };

    return this.httpService.post<void>('webinar/attendee', data);
  }

  public acceptInvitationForWebinar(webinarId: string, userId: string): Observable<void> {
    const data = {
      webinarId,
      userId
    };

    return this.httpService.post<void>('webinar/accepted-invitation', data);
  }


  public rejectInvitationForWebinar(webinarId: string, userId: string): Observable<void> {
    const data = {
      webinarId,
      userId
    };

    return this.httpService.post<void>('webinar/rejected-invitation', data);
  }

  public deleteUserFromWebinar(webinarId: string, userId: string): Observable<void> {
    const params = new HttpParams()
      .set('webinar_id', webinarId)
      .set('user_id', userId);

    return this.httpService.delete<void>('webinar/attendee', params);
  }

  public getWebinarSeries(params = new HttpParams()): Observable<WebinarSeriesModel[]> {
    params = params
      .set('vocabulary', 'webinar_serie')
      .set('display', 'teaser')
      .set('sort', 'isUpcoming:ASC')
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');

    return this.httpService.get<DataResponse<WebinarSeriesModel>>('v2/taxonomies', params).pipe(map(res => res.items));
  }

  public getWebinarSeriesById(id: string): Observable<WebinarSeriesModel[]> {
    const params: HttpParams = new HttpParams()
      .set('id', id)
      .set(interceptorsToken.HANDLE_PERMISSION_DENIED_PARAM, 'true');
    return this.httpService.get<DataResponse<WebinarSeriesModel>>('v2/taxonomies', params).pipe(map(res => res.items));
  }

  public joinWebinar(webinarId: string): Observable<void> {
    return this.httpService.post<void>('webinar/join', { webinarId });
  }
}
