import { Component, Input, OnInit } from '@angular/core';
import { ChatChannel } from '@app/model/chat-channel';
import { ChatChannelType } from '@app/model/enums/chat-channel-type.enum';
import { SettingsService } from '@app/services/settings.service';
import { BundleType, ImageModel } from 'library-explorer';

@Component({
  selector: 'app-chat-channel-image',
  templateUrl: './chat-channel-image.component.html',
  styleUrls: ['./chat-channel-image.component.scss']
})
export class ChatChannelImageComponent implements OnInit {
  @Input() public channel: ChatChannel;
  @Input() public size = 32;

  public globalChatLogo: ImageModel;

  public readonly chatChannelTypes: typeof ChatChannelType = ChatChannelType;
  public readonly BundleTypes: typeof BundleType = BundleType;

  constructor(private readonly settingsService: SettingsService) { }

  ngOnInit(): void {
    this.settingsService.getSettings()
      .subscribe(data => this.globalChatLogo = data.general.platformLogo);
  }

}
