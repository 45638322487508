import { Inject, Injectable } from '@angular/core';
import { BaseSettingsGetService } from './base-settings.service';
import { SETTINGS_SERVICE } from '../constants';
import { BundleType, ImageModel } from '../models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsMediaPresignedUrl } from '../pipes';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderService {

  public overridePlaceholders: { [key: string]: ImageModel };
  public defaultPlaceholders = {
    [BundleType.CLASS]: '/library-explorer/assets/images/placeholders/quiz_placeholder.svg',
    [BundleType.COURSE]: '/library-explorer/assets/images/placeholders/course_placeholder.svg',
    [BundleType.MODULE]: '/library-explorer/assets/images/placeholders/module_placeholder.svg',
    [BundleType.LESSON]: '/library-explorer/assets/images/placeholders/lesson_placeholder.svg',
    [BundleType.QUIZ]: '/library-explorer/assets/images/placeholders/quiz_placeholder.svg',
    [BundleType.POLL_QUIZ]: '/library-explorer/assets/images/placeholders/quiz_placeholder.svg',
    [BundleType.ASSIGNMENT]: '/library-explorer/assets/images/placeholders/quiz_placeholder.svg',
    [BundleType.LEARNING_PATH]: '/library-explorer/assets/images/placeholders/learning_path_placeholder.svg',
    [BundleType.CHAT_CHANNEL]: '/library-explorer/assets/images/placeholders/course_placeholder.svg',
    [BundleType.BADGE]: '/library-explorer/assets/images/placeholders/badge_placeholder.png',
  }

  constructor(
    @Inject(SETTINGS_SERVICE) private readonly settingsService: BaseSettingsGetService,
    private readonly settingsMediaPresignedUrl: SettingsMediaPresignedUrl
  ) {
    this.setPlaceholders();
  }

  private setPlaceholders() {
    this.settingsService.getSettings().subscribe((settings) => {
      const { placeholders } = settings.branding;

      if (!placeholders) {
        this.overridePlaceholders = {};
        return;
      }

      this.overridePlaceholders = {
        [BundleType.CLASS]: placeholders.classImage,
        [BundleType.COURSE]: placeholders.courseImage,
        [BundleType.MODULE]: placeholders.moduleImage,
        [BundleType.LESSON]: placeholders.lessonImage,
        [BundleType.QUIZ]: placeholders.quizImage,
        [BundleType.POLL_QUIZ]: placeholders.quizImage,
        [BundleType.ASSIGNMENT]: placeholders.quizImage,
        [BundleType.LEARNING_PATH]: placeholders.learningPathImage,
        [BundleType.CHAT_CHANNEL]: placeholders.chatChannelImage,
        [BundleType.BADGE]: placeholders.badgeImage,
      };
    })
  }

  public getPlaceholder(bundle: BundleType): Observable<{url: string, isCustom: boolean}> {
    if (!bundle) {
      return of();
    }

    if (!this.overridePlaceholders[bundle]) {
      return of({url: this.defaultPlaceholders[bundle], isCustom: false});
    }

    return this.settingsMediaPresignedUrl.transform(this.overridePlaceholders[bundle]).pipe(
      map((url: string) => ({url, isCustom: true}))
    );
  }

}
