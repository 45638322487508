<img 
  *ngIf="channel?.coverUrl || (channel?.type === chatChannelTypes.GLOBAL && globalChatLogo); else placeholderTmp"
  [class.width.px]="size"
  [class.height.px]="size"
  class="chat-item-image"
  [class.global-logo]="channel?.type === chatChannelTypes.GLOBAL"
  [cropImage]="(channel.coverUrl ? ({ uri: channel.coverUrl, key: channel.image?.key, provider: channel.image?.provider } | preSignedUrl) : (globalChatLogo | settingsMediaPresignedUrl)) | async"
  [additionalOptions]="{width: 100, height: 100, func: 'bound'}" />

<ng-template #placeholderTmp>
  <img class="chat-item-image" [placeholderImage]="BundleTypes.CHAT_CHANNEL" alt="chat-channel-placeholder">
</ng-template>