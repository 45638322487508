import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from '@app/shared/components/swiper/swiper.component';
import { FieldName, NodeModel } from 'library-explorer';
import { PayableNodeNavigationService } from 'src/app/services/payable-node-navigation.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-discover-content-block',
  templateUrl: './discover-content-block.component.html',
  styleUrls: ['./discover-content-block.component.scss']
})
export class DiscoverContentBlockComponent implements OnInit {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  @Input() block: any;

  public swiperOptions = {
    slidesPerView: 'auto',
    showOverflow: true
  };

  public fieldNames: typeof FieldName = FieldName;
  public paymentEnabled = false;

  constructor(
    private readonly payableNodeNavigationService: PayableNodeNavigationService,
    private readonly paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.paymentService.getPaymentConfig().subscribe(res => {
      this.paymentEnabled = !!res.enabled;
    });
  }

  public navigate(event: MouseEvent, item: NodeModel): void {
    this.payableNodeNavigationService.navigate(item, event);
  }

}

