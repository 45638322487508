import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from '@app/shared/components/swiper/swiper.component';

@Component({
  selector: 'app-feature-highlight',
  templateUrl: './feature-highlight.component.html',
  styleUrls: ['./feature-highlight.component.scss']
})
export class FeatureHighlightComponent implements OnInit {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  @Input() block: any;

  public swiperOptions = {
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      }
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
