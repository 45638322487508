import { Component, HostBinding, Inject, Input } from '@angular/core';
import { OVERRIDE_ICONS_FROM_SETTINGS, SETTINGS_SERVICE } from '../../constants';
import { BaseSettingsGetService } from '../../services';

@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() public icon: string;
  @Input() public size = 24;
  @Input() public bold = false;
  @Input() public duotone = false;
  @Input() public fill = false;

  @Input() @HostBinding('class.inline') inline = false;

  public svgIconUrl: string;

  constructor(
    @Inject(OVERRIDE_ICONS_FROM_SETTINGS) public readonly iconOverrideFromSettings: boolean,
    @Inject(SETTINGS_SERVICE) private readonly settingsService: BaseSettingsGetService) { }

  ngOnInit() {  
    this.loadIconComponent();
  }

  public loadFailed(): void {
    this.svgIconUrl = null;
  }

  private loadIconComponent() {
    if (!this.iconOverrideFromSettings) {
      return;
    }
  
    this.settingsService.getSettings()
      .subscribe(data => {      
        const extractFileName = (path: string) => {
          const match = path?.match(/\/([^/]+)\.[^/]+$/);
          return match ? match[1] : null;
        };

        const iconsPackageContent = data.branding?.icons?.package?.content?.map(item => ({
          key: extractFileName(item.key),
          uri: item.uri
        })) || [];

        this.svgIconUrl = iconsPackageContent.find(item => item.key === this.icon)?.uri;
      });
  }

}
