import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ViewCertificateComponent } from './components/view-certificate/view-certificate.component';


@NgModule({
  declarations: [
    ViewCertificateComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ViewCertificateComponent
  ]
})
export class CertificateModule { }
