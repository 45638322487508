<div 
  class="chat-message" 
  [class.user-message]="currentUserMessage">

  <lib-user-avatar class="sender-avatar" [size]="32" [user]="sender"></lib-user-avatar>

  <div class="chat-message-body">
    <lib-icon 
      *ngIf="canDelete || canEdit"
      class="chat-message-context-button" 
      icon="dots-three-vertical" 
      [size]="16" 
      [matMenuTriggerFor]="chatMenu">
    </lib-icon>
    
    <mat-menu #chatMenu="matMenu" class="base-context-menu">
      <button class="base-menu-button" mat-menu-item (click)="editMessage()" *ngIf="canEdit">
        <lib-icon icon="pencil-simple"></lib-icon>
        {{'CHAT.edit' | translate}}
      </button>

      <button class="base-menu-button" mat-menu-item (click)="deleteMessage()" *ngIf="canDelete">
        <lib-icon icon="trash"></lib-icon>
        {{'CHAT.delete' | translate}}
      </button>
    </mat-menu>

    <div class="chat-message-head">
      <span class="message-sender">{{ item.sender?.nickname || 'Admin' }}</span>

      <span class="message-date">{{ item.createdAt | customDate: 'dateTimeMin' }}</span>
    </div>

    <ng-container [ngSwitch]="item.customType">
      <ng-container *ngSwitchCase="messageTypes.VIDEO_MESSAGE">
        <lib-recorded-video-message 
          [theme]="currentUserMessage ? 'primary' : 'default'" 
          [videoShape]="messageOptions?.layout"
          [video]="messageMedia"
          [src]="item.url || (messageMediaUrl | async)">
        </lib-recorded-video-message>
      </ng-container>

      <ng-container *ngSwitchCase="messageTypes.AUDIO_MESSAGE">
        <lib-audio-waveform-player 
          [theme]="currentUserMessage ? 'primary' : 'default'" 
          [src]="item.url || (messageMediaUrl | async)">
        </lib-audio-waveform-player>
      </ng-container>

      <ng-container *ngSwitchCase="messageTypes.IMAGE_MESSAGE">
        <img class="user-media-message" [src]="item.url || (messageMediaUrl | async)" (load)="mediaLoaded.emit()">
      </ng-container>

      <ng-container *ngSwitchCase="messageTypes.FILE_MESSAGE">
        <div class="message-content">
          <lib-icon icon="paperclip"></lib-icon>
          <a [attr.href]="item.url || (messageMediaUrl | async)" target="_blank">{{ messageMedia?.filename || messageMedia?.name }}</a>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault> 
        <ng-container [ngSwitch]="item.messageType">
          <ng-container *ngSwitchCase="'file'">
            <ng-container [ngSwitch]="item.type">
            
              <ng-container *ngSwitchCase="item.type == 'image/jpeg' || item.type == 'image/png' ? item.type : ''">
                <img class="user-media-message" [src]="item.url" (load)="mediaLoaded.emit()">
              </ng-container>
            
              <ng-container *ngSwitchDefault>
                <div class="message-content">
                  <lib-icon icon="paperclip"></lib-icon>
                  <a [attr.href]="item.url" target="_blank">{{item.name}}</a>
                </div>
              </ng-container>
          
            </ng-container>
          
          </ng-container>
      
          <ng-container *ngSwitchDefault>
            <div class="message-content" [innerHtml]="item.message | chatMessage"></div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
