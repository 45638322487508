import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from '@app/app.module';

// import function to register Swiper custom elements
import { register as registerSwiper } from 'swiper/element/bundle';
// register Swiper custom elements

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

registerSwiper();
