import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ContentHighlightBlock } from 'src/app/model/content-highlight-block';
import { BundleType, SettingsCourseDetailsScreenOptions } from 'library-explorer';
import { HighlightBlockContentType } from 'src/app/model/enums/highlight-block-content-type.enum';
import { SettingsService } from '@app/services/settings.service';
import { PayableNodeNavigationService } from 'src/app/services/payable-node-navigation.service';
import { PaymentService } from 'src/app/services/payment.service';
import { MobileCardStyleType } from '@app/model/enums/mobile-card-style.enum';
import { SwiperComponent } from '@app/shared/components/swiper/swiper.component';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-content-highlight-block',
  templateUrl: './content-highlight-block.component.html',
  styleUrls: ['./content-highlight-block.component.scss']
})
export class ContentHighlightBlockComponent implements OnInit {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  @Input() public set block(value: ContentHighlightBlock) {
    if (value) {
      this._block = value;
      this.title = value.title;

      this.getContentHighlights(value);
    }
  }

  public get block() {
    return this._block;
  }

  public items: any[] = [];
  public title: string;

  public isLoading = true;

  public swiperOptions = {
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      }
    }
  };
  public carouselConfig = {
    lazyLoad: 'ondemand',
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    touchMove: true,
    swipeToSlide: true,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  public readonly bundleTypes: typeof BundleType = BundleType;
  public readonly mobileCardStyleTypes: typeof MobileCardStyleType = MobileCardStyleType;

  public isPaymentEnabled = false;
  public skipModule = false;
  public courseDetailsEnabled = false;
  private _block: ContentHighlightBlock;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly paymentService: PaymentService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly payableNodeNavigationService: PayableNodeNavigationService
  ) { }

  ngOnInit() {
    combineLatest([
      this.settingsService.getSettings(),
      this.authService.isAuthorized()
    ]).subscribe(([settings, isLoggedIn]) => {
      this.skipModule = settings.system.skip_module;
      this.courseDetailsEnabled = settings.course.courseDetailsScreen === SettingsCourseDetailsScreenOptions.ENABLED_FOR_ALL_USERS
        || (settings.course.courseDetailsScreen === SettingsCourseDetailsScreenOptions.ENABLED_FOR_LOGGED_IN_USERS && isLoggedIn);
    });

    this.paymentService.getPaymentConfig().subscribe(res => {
      this.isPaymentEnabled = !!res.enabled;
    });
  }

  public navigate(event, item: any): void {
    switch (item.bundle) {
      case BundleType.CLASS:
        this.router.navigate(['/courses'], { queryParams: { classID: item.id }, state: { autoOpen: true }});
        break;
      case BundleType.COURSE:
        const data = {
          ...item,
          bundle: BundleType.COURSE,
          uuid: item.id
        };
        this.payableNodeNavigationService.navigate(data, event);

        break;
      case BundleType.MODULE:
        this.router.navigate(['/lessons'], { 
          queryParams: { moduleID: item.id, courseID: item.parentCourseId }, 
          state: { autoOpen: true }});
        break;
      case BundleType.LESSON:
      case BundleType.QUIZ:
        this.router.navigate(['/chapters'], { 
          queryParams: { lessonID: item.id, courseID: item.parentCourseId }, 
          state: { autoOpen: true }});
        break;
    }
  }

  public openDetails(item: any): void {
    this.payableNodeNavigationService.openCourseDetails(item, true).subscribe();
  }

  private getContentHighlights(value: ContentHighlightBlock): void {
    switch (value.gettingContentType) {
      case HighlightBlockContentType.AUTOMATIC:
      case HighlightBlockContentType.MANUAL:
      case HighlightBlockContentType.AUTOMATIC_IN_PROGRESS:
        this.items = value.automaticContentItems || [];
        break;
      case HighlightBlockContentType.MANUAL_SELECTION:
        this.items = value.contents || [];
        break;
    }
  }
}
