<div class="notifications-list-dialog">
  <div class="notifications-dialog-header">
    <h3 class="notifications-dialog-title" cdkFocusInitial>{{'COMMON.notifications' | translate}} ({{ total || 0 }})</h3>

    <div class="dialog-header-buttons">
      <app-icon-button class="dialog-close-button" icon="x" (clicked)="closeDialog()"></app-icon-button>
    </div>
  </div>

  <div class="notifications">
    <ng-container *ngIf="notifications?.length; else emptyState">
      <ng-scrollbar class="notifications-scrollbar" [class.has-scroll]="hasScroll" [autoHeightDisabled]="false" (updated)="scrollUpdated()">
        <div class="notifications-list">
          <div 
            class="notification" 
            *ngFor="let item of notifications; trackBy: trackByFn" 
            [ngClass]="{ 'new-notification': !item.seen }"
            (click)="notificationNavigate(item)">
            <div class="notification-icon">
              <lib-icon icon="book-open-text"></lib-icon>
            </div>
    
            <div class="notification-body">
              <h6 class="notification-title">
                {{ item.message?.title }}
              </h6>  
    
              <span class="notification-message">
                {{ item.message?.text }}
              </span>  
    
              <div class="notification-date">
                <div class="new-indicator">
                  {{ 'COMMON.new' | translate }}
                </div>
    
                <div class="notification-delivered-time">
                  {{ (item.popupNotificationDeliveredAt || item.regularNotificationDeliveredAt || item.pushNotificationDeliveredAt || item.emailDeliveredAt) | dateDiff }}
                </div>
              </div>  
            </div>
    
          </div>
        </div>
      </ng-scrollbar>
    </ng-container>
  </div>
  
  <ng-template #emptyState>
    <app-list-empty-state 
      *ngIf="!isLoading; else loadingTmp"
      class="notifications-empty-state"
      icon="tray"
      [title]="'NOTIFICATIONS.no_new_notications' | translate"
      [text]="'NOTIFICATIONS.notifications_will_show_up_here' | translate">
    </app-list-empty-state>
  </ng-template>

  <ng-template #loadingTmp>
    <div class="notifications-list">
      <div class="notification placeholder" *ngFor="let item of placeholders">
        <lib-skeleton-loader height="40px" width="40px" className="circle"></lib-skeleton-loader>

        <div class="notification-body">
          <lib-skeleton-loader height="22px" width="60%"></lib-skeleton-loader>
          <lib-skeleton-loader height="16px" width="80%"></lib-skeleton-loader>
          <lib-skeleton-loader height="12px" width="40%"></lib-skeleton-loader>
        </div>
      </div>
    </div>
  </ng-template>

</div>

