<div 
  class="quick-searh-input" 
  [ngClass]="{ expanded: dialogMode || expanded, dialog: dialogMode  }"
  (clickOutside)="colapseSearchPanel()">
  <div class="search-input">
    <lib-icon class="search-icon" icon="magnifying-glass"></lib-icon>
    <input 
      type="text" 
      [formControl]="searchControl" 
      class="input input--rounded input--placeholder-grey" 
      [placeholder]="(placeholder || 'COMMON.start_search_here') | translate"
      (focus)="expandSearchPanel()"
      (keydown.enter)="submitSearchQuery()"
      enterkeyhint="search">
  
    <div class="input-buttons">
      <button 
        *ngIf="searchControl?.value" 
        mat-icon-button 
        class="button button--icon clear-icon"
        (click)="clearSearch()">
        <lib-icon icon="x" [size]="24"></lib-icon>
      </button> 

      <button mat-flat-button class="button button--transparent button--small close-button" (click)="closeSearchDialog()">
        {{ 'COMMON.cancel' | translate }}
      </button>
    </div>

  </div>

  <div class="search-quick-results">
    <ng-container *ngIf="!bundle && resultsBundles?.length">
      <div class="divider"></div>

      <div class="chip-container">
        <div class="chip-item" *ngFor="let item of resultsBundles" (click)="searchByBundle.emit(item)">
          {{ ('ENTITY_TYPE.' + item) | translate | titlecase }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!expanded && enableLastSearch && lastSearches?.length">
      <div class="divider"></div>

      <div class="last-search-container">
        <span class="search-results-label">{{ 'SEARCH.last_search' | translate }}</span>

        <div class="last-search-list">
          <div class="last-search" *ngFor="let item of lastSearches" (click)="useLastSearchItem(item)">
            <span class="item-title" ellipsis [ellipsis-content]="item.search"></span>

            <lib-icon class="clear-icon" icon="x" [size]="14" (click)="clearLastSearchItem($event, item)"></lib-icon>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="expanded && enabledSearchByTag">
      <div class="divider"></div>

      <div class="tags-quick-results">
        <span class="search-results-label">{{ 'SEARCH.with_tag' | translate }}</span>

        <div class="chip-container">
          <ng-container *ngIf="tags?.length; else tagsPlaceholderTmp">
            <div class="chip-item" *ngFor="let item of tags" (click)="searchByTag.emit(item.id)">
              {{ item.title }}
            </div>
          </ng-container>

          <ng-template #tagsPlaceholderTmp>
            <ng-container *ngIf="tagsLoading; else tagsEmptyTmp">
              <lib-skeleton-loader class="chip-placeholder" *ngFor="let item of loadingPlaceholers"></lib-skeleton-loader>
            </ng-container>
          </ng-template>

          <ng-template #tagsEmptyTmp>
            <span class="search-results-label tags-empty-label">{{ 'SEARCH.no_tags_found' | translate }}</span>
          </ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="expanded">
      <div class="divider"></div>

      <ng-scrollbar class="results-scroll-container" [class.is-loading]="dataLoading" [autoHeightDisabled]="false">
        <div class="quick-results-section" [class.multiple-group]="dialogMode">
        
          <div class="quick-results-labels">
            <ng-container *ngTemplateOutlet="resultsHeaderTemplate || resultsHeaderTemplateDefault"></ng-container>
          </div>
  
          <ng-container *ngIf="dataGroups?.length; else dataPlaceholderTmp">
  
            <div class="results-group" *ngFor="let group of dataGroups; let last = last" [class.last-group]="last">
              <label class="results-group-label">
                {{ group.label | translate }}
              </label>
  
              <div class="search-results-list">
                <div class="search-result" *ngFor="let item of group.items">
                  <ng-container *ngTemplateOutlet="resultTemplate || resultTemplateDefault; context: { item: item}"></ng-container>
                </div>
              </div>
  
              <div class="divider"></div>
            </div>
  
          </ng-container>
        </div>
      </ng-scrollbar>
    </ng-container>

    <ng-template #dataPlaceholderTmp>
      <ng-container *ngIf="dataLoading; else dataEmptyTmp">
        <lib-skeleton-loader class="data-placeholder" *ngFor="let item of loadingPlaceholers"></lib-skeleton-loader>
      </ng-container>
    </ng-template>

    <ng-template #dataEmptyTmp>
      <span class="search-results-label data-empty-label">{{ 'COMMON.no_results' | translate }}</span>
    </ng-template>

    <div class="divider"></div>

    <div class="search-input-footer">
      
      <ng-container *ngIf="expanded; else emptyFooterStateTmp">
        <span class="search-results-label footer-results-label">{{ 'SEARCH.all_search_result_for' | translate : { key: searchControl?.value | slice : 0 : 20 } }}</span>

        <span class="search-results-label footer-results-label">{{ 'COMMON.press_enter' | translate }}</span>

        <button class="button button--transparent button--outline mobile-only search-result-button" mat-flat-button (click)="submitSearchQuery()">
          {{ 'SEARCH.see_all_results_for' | translate : { key: searchControl?.value | slice : 0 : 20 } }}
        </button>
      </ng-container>

      <ng-template #emptyFooterStateTmp>
        <span class="search-results-label">{{'SEARCH.start_typing_to_see_search_results' | translate}}</span>
      </ng-template>
      
    </div>
  </div>
  
</div>

<ng-template #resultsHeaderTemplateDefault>
  <div class="d-flex justify-content-between">
    <span>{{ 'COMMON.title' | translate}}</span>
  </div>
</ng-template>

<ng-template #resultTemplateDefault let-item="item">
  <div class="content-row-result" [class.locked]="item.isLocked" (click)="navigateToEntity(item)">
    <div class="content-row-result-icon">
      <ng-container [ngSwitch]="item.bundle">
        <lib-icon *ngSwitchCase="bundles.WEBINAR" icon="monitor-play"></lib-icon>
        <lib-icon *ngSwitchCase="bundles.LIBRARY" icon="books"></lib-icon>
        <lib-icon *ngSwitchDefault icon="book-open-text"></lib-icon>
      </ng-container>
    </div>

    <div class="content-row-result-row">
      <div class="content-row-result-name">
        <span class="item-bundle">{{ ('COMMON.' + item.bundle) | translate }}</span>
        <span class="item-title" ellipsis [ellipsis-content]="item.title"></span>
      </div>

      <lib-icon 
        *ngIf="item.isLocked"
        icon="lock-simple"
        [size]="18" 
        [matTooltip]="'ERRORS.content_locked' | translate"></lib-icon>

      <div 
        *ngIf="!item.isLocked && item.payable && !item.isPaid && paymentEnabled"
        class="content-row-result-price">
        {{ item.price }} {{item.currency | uppercase}}
      </div>
    </div>
  </div>
</ng-template>