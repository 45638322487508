import { Directive, ElementRef, Input } from '@angular/core';

import { BackgroundConfiguration, BackgroundSizeOption, HorizontalPositionOption, VerticalPositionOption } from '../models';
import { TokenApiService } from '../services';
import { CloudImageService } from '../services/cloud-image.service';
import { SharedLibraryService } from '../services/shared-library.service';
import { CropImageDirective } from './crop-image.directive';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[mediaBackground]'
})
export class MediaBackgroundDirective extends CropImageDirective {
  @Input() public shouldCreateOverlay = true;
  @Input() public overlayUseFullHeight = false;

  @Input() public set mediaBackground(value: BackgroundConfiguration) {
    this._mediaBackground = value;
    this.initializeElementBackground();
  }

  public get mediaBackground() {
    return this._mediaBackground;
  }

  private mediaQuery = window.matchMedia('(min-width: 768px)');

  private _mediaBackground!: BackgroundConfiguration;

  private imageOverlay: HTMLElement;
  private colorOverlay: HTMLElement;

  constructor(
    protected override readonly sharedLibraryService: SharedLibraryService,
    protected override readonly el: ElementRef,
    protected override readonly tokenApiService: TokenApiService,
    protected override cloudImageService: CloudImageService) {
    super(sharedLibraryService, el, tokenApiService, cloudImageService);
    this.mediaQuery.addEventListener('change', () => this.initializeElementBackground());
  }

  protected initializeElementBackground(): void {
    if (!this._mediaBackground) {
      this.el.nativeElement.style.backgroundColor = null;
      this.el.nativeElement.style.backgroundImage = null;
      this.cropImage = '';
      this.destroyOverlay();

      return;
    }

    this.setBackgroundImageOverlay();
  }

  private destroyOverlay(): void {
    if (this.imageOverlay) {
      this.imageOverlay.remove();
      this.imageOverlay = null;
    }

    if (this.colorOverlay) {
      this.colorOverlay.remove();
      this.colorOverlay = null;
    }
  }

  private createOverlay(): void {
    if (!this.shouldCreateOverlay || this.imageOverlay) {
      return;
    }

    const imageOverlay = document.createElement('div');
    const colorOverlay = document.createElement('div');

    const computedStyle = window.getComputedStyle(this.el.nativeElement);
    const paddingTop = parseFloat(computedStyle.paddingTop) || 0;
    const paddingBottom = parseFloat(computedStyle.paddingBottom) || 0;

    const baseStyle = {
      position: 'absolute',
      width: '100%',
      height: this.overlayUseFullHeight ? '100%' : `calc(100% - ${paddingTop + paddingBottom}px)`,
      top: '0',
      left: '0',
      zIndex: -1
    };

    Object.assign(imageOverlay.style, baseStyle);
    Object.assign(colorOverlay.style, {
      ...baseStyle,
      zIndex: -2
    });
    this.el.nativeElement.appendChild(colorOverlay);
    this.el.nativeElement.appendChild(imageOverlay);
    this.targetElement = this.imageOverlay = imageOverlay;
    this.colorOverlay = colorOverlay;
  }

  protected setBackgroundImageOverlay(): void {
    this.createOverlay();
    const isDesktop = this.mediaQuery.matches;
    const { mediaBackground, mobileMediaBackground, backgroundColor } = this._mediaBackground;

    const backgroundMedia = isDesktop ? mediaBackground : mobileMediaBackground || mediaBackground;
    const isEmptyArray = Array.isArray(backgroundMedia) && !backgroundMedia?.length;

    const element = this.getTargetElement();
    const colorElement = this.colorOverlay;

    if (colorElement) {
      colorElement.style.backgroundColor = backgroundColor;
    } else {
      element.style.backgroundColor = backgroundColor;
    }
    
    if (!backgroundMedia || isEmptyArray) {
      element.style.backgroundImage = null;
      this.cropImage = '';

      return;
    }

    const [backgroundSize, backgroundPosition] = [
      isDesktop ? this._mediaBackground.backgroundSize : this._mediaBackground.mobileBackgroundSize, 
      isDesktop ? this._mediaBackground.backgroundPosition : this._mediaBackground.mobileBackgroundPosition, 
    ];

    this.additionalOptions = {
      width: this.dynamicWidth ? element.offsetWidth * this.dynamicWidthCoeff : this.imageWidth,
      ...(backgroundMedia?.additionalMediaProps ? backgroundMedia.additionalMediaProps : {}),
      func: 'bound'
    };

    this.crop = backgroundMedia.crop;

    element.style.backgroundSize = backgroundSize || BackgroundSizeOption.COVER;
    const { horizontal = HorizontalPositionOption.LEFT, vertical = VerticalPositionOption.TOP } = backgroundPosition || {};
    element.style.backgroundPosition = `${vertical} ${horizontal}`;
    element.style.backgroundRepeat = 'no-repeat';

    this.cropImage = backgroundMedia.uri || backgroundMedia.url || '';
  }
}
