import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CertificateService } from '@app/components/certificate/services/certificate.service';
import { UserCertificate } from '@app/model/user-certificate.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-certificate-modal',
  templateUrl: './certificate-modal.component.html',
  styleUrls: ['./certificate-modal.component.scss']
})
export class CertificateModalComponent implements OnInit {

  public isDownloading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { certificate: UserCertificate, shareEnabled: boolean },
    public dialogRef: MatDialogRef<CertificateModalComponent>,
    private readonly certificateService: CertificateService
  ) {}

  ngOnInit(): void {
  }

  public share(): void {
    this.certificateService.shareCertificateOnLinkedIn(this.data.certificate);
  }

  public download(): void {
    if (this.isDownloading) {
      return;
    }

    this.isDownloading = true;
    this.certificateService.downloadCerfiticate(this.data.certificate).pipe(
      finalize(() => this.isDownloading = false)
    ).subscribe();
  }

  public preview(): void {
    this.dialogRef.close();
    this.certificateService.previewCertificate(this.data.certificate);
  }

}
