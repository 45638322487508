<div class="base-page-banner">

  <app-quick-search-input 
    class="page-banner-search-input quick-search"
    [bundle]="searchBundles"
    (search)="navigateSearchPage($event)">
  </app-quick-search-input>

  <h2 class="page-banner-title">
    {{ pageSettings?.title || ('COMMON.catalog' | translate)}}
    <label class="offline-mode-indicator" *ngIfOffline="false">{{"COMMON.offline_mode" | translate}}</label>
  </h2>
  
  <div *ngIf="!pageSettings?.removeBannerShadow" class="page-banner-overlay"></div>

  <img 
    class="page-banner-image"
    *ngIfMediaQuery="'(min-width: 640px)'; else mobileImageTmp"
    [cropImage]="pageSettings?.image | settingsMediaPresignedUrl | async"
    [additionalOptions]="{ height: 320 }">

  <ng-template #mobileImageTmp>
    <ng-container *ngIf="pageSettings?.phoneImage || pageSettings?.image as image">
      <img 
        class="page-banner-image" 
        [cropImage]="image | settingsMediaPresignedUrl | async"
        [additionalOptions]="{ height: 240 }">
    </ng-container>
  </ng-template>
</div>
