import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private contentOnlyMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private topPaddingOnPhone$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private nativeNavigationBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  public get contentOnlyMode(): Observable<boolean> {
    return this.contentOnlyMode$.asObservable();
  }

  public get topPaddingOnPhone(): Observable<boolean> {
    return this.topPaddingOnPhone$.asObservable();
  }

  public get nativeNavigationBar(): Observable<boolean> {
    return this.nativeNavigationBar$.asObservable();
  }

  public get contentOnlyModeValue(): boolean {
    return this.contentOnlyMode$.value;
  }

  public get nativeNavigationBarValue(): boolean {
    return this.nativeNavigationBar$.value;
  }

  public toggleContentOnlyMode(): void {
    this.contentOnlyMode$.next(!this.contentOnlyMode$.value);
  }

  public enableContentOnlyMode(): void {
    this.contentOnlyMode$.next(true);
  }

  public disableContentOnlyMode(): void {
    this.contentOnlyMode$.next(false);
  }

  public removeTopPaddingOnPhone(): void {
    this.topPaddingOnPhone$.next(true);
  }

  public resetTopPaddingOnPhone(): void {
    this.topPaddingOnPhone$.next(false);
  }

  public enableNativeNavigationBar(): void {
    this.nativeNavigationBar$.next(true);
  }

  public removeNativeNavigationBar(): void {
    this.nativeNavigationBar$.next(false);
  }

  public setNativeNavigationBarHeight(): void {
    this.document.body.style.setProperty('--lms-native-navigation-bar-height', `100px`);
  }
  
}
