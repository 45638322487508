import { BehaviorSubject } from 'rxjs';
import { CookieConsentAgreement, CookieConsentProviderType, LegalDocumentModel } from '../../models';

export abstract class CookieConsentProvider {
  public abstract cookieConsentAgreement: BehaviorSubject<CookieConsentAgreement | null>;

  public abstract openCookieConsentDialog(settings: any, documents: LegalDocumentModel[]): Promise<void>;
  
  public abstract togglePreferencesSettings(): void;

  public abstract initialized: BehaviorSubject<boolean>;

  public abstract type: CookieConsentProviderType;
}
