<div *ngIf="certificate" class="certificate-card" [ngClass]="'layout-' + layout" (click)="openModal()">
  <div class="certificate-card__body">
    <div
      *ngIf="layout === 'regular'; else bodyIconTemplate"
      class="certificate-card__body--image"
      [cropImage]="certificate?.customCertificate?.mediaImage | preSignedUrl | async" 
      [additionalOptions]="{width: 200}" >
      <div class="certificate-card__body--status"></div>
    </div>
    <ng-template #bodyIconTemplate>
      <div class="certificate-card__body--icon">
        <lib-icon icon="certificate" size="32"></lib-icon>
      </div>
    </ng-template>
    <div class="certificate-card__body--title-wrapper">
      <div class="certificate-card__body--title">
        {{certificate.title}}
      </div>
      <div *ngIf="certificate.dateOfCompletion" class="certificate-card__body--subtitle">
        {{'COMMON.granted' | translate}} {{certificate.dateOfCompletion | customDate}}
      </div>
    </div>
  </div>
  <div class="certificate-card__buttons" (click)="$event.stopPropagation()">
    <button mat-icon-button class="button button--icon" (click)="preview()">
      <lib-icon icon="eye"></lib-icon>
    </button>
    <button *ngIf="shareEnabled" mat-icon-button class="button button--icon" (click)="share()">
      <lib-icon icon="share-network"></lib-icon>
    </button>
    <button mat-icon-button class="button button--icon" (click)="download()">
      <lib-icon *ngIf="!isDownloading; else donwloadIconTemplate" icon="download-simple"></lib-icon>
      <ng-template #donwloadIconTemplate>
        <mat-spinner diameter="24"></mat-spinner>
      </ng-template>
    </button>
  </div>
  <div class="certificate-card__more">
    <button mat-icon-button class="button button--icon button--icon-extra-small" [matMenuTriggerFor]="certificateMenu">
      <lib-icon icon="dots-three-vertical"></lib-icon>
    </button>

    <mat-menu #certificateMenu="matMenu" class="base-context-menu">
      <button *ngIf="false" mat-menu-item class="base-menu-button" (click)="open()">
        <lib-icon icon="eye"></lib-icon> {{'COMMON.preview' | translate}}
      </button>
      <button *ngIf="shareEnabled" mat-menu-item class="base-menu-button" (click)="share()">
        <lib-icon icon="share-network"></lib-icon> {{'COMMON.share' | translate}}
      </button>
      <button mat-menu-item class="base-menu-button" (click)="download()">
        <lib-icon icon="download-simple"></lib-icon> {{'COMMON.download' | translate}}
      </button>
    </mat-menu>
  </div>
</div>
