import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CertificateService } from '@app/components/certificate/services/certificate.service';
import { UserCertificate } from '@app/model/user-certificate.model';
import { finalize } from 'rxjs/operators';
import { CertificateModalComponent } from '../certificate-modal/certificate-modal.component';
import { CertificatePreviewModalComponent } from '../certificate-preview-modal/certificate-preview-modal.component';

@Component({
  selector: 'app-certificate-card',
  templateUrl: './certificate-card.component.html',
  styleUrls: ['./certificate-card.component.scss']
})
export class CertificateCardComponent implements OnInit {

  @Input() certificate: UserCertificate;
  @Input() shareEnabled: boolean;
  @Input() layout: 'small'|'regular' = 'regular';

  public isDownloading = false;

  constructor(
    private readonly certificateService: CertificateService,
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  public share(): void {
    this.certificateService.shareCertificateOnLinkedIn(this.certificate);
  }

  public download(): void {
    if (this.isDownloading) {
      return;
    }

    this.isDownloading = true;
    this.certificateService.downloadCerfiticate(this.certificate).pipe(
      finalize(() => this.isDownloading = false)
    ).subscribe();
  }

  public preview(): void {
    this.dialog.open(CertificatePreviewModalComponent, {
      disableClose: false,
      closeOnNavigation: true,
      panelClass: ['mobile-full-width-dialog', 'dialog-no-padding', 'dialog-no-margin'],
      data: {...this.certificate}
    });
  }

  public openModal(): void {
    this.dialog.open(CertificateModalComponent, {
      disableClose: false,
      closeOnNavigation: true,
      panelClass: ['mobile-full-width-dialog', 'dialog-no-padding', 'dialog-no-margin'],
      data: {certificate: this.certificate, shareEnabled: this.shareEnabled}
    });
  }

}
