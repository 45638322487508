import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserCertificate } from '@app/model/user-certificate.model';

@Component({
  selector: 'app-certificate-preview-modal',
  templateUrl: './certificate-preview-modal.component.html',
  styleUrls: ['./certificate-preview-modal.component.scss']
})
export class CertificatePreviewModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public certificate: UserCertificate,
    public dialogRef: MatDialogRef<CertificatePreviewModalComponent>,
  ) { }

  ngOnInit(): void {
  }

}
