import { Injectable } from '@angular/core';
import { HttpOptions, PluginListenerHandle } from '@capacitor/core';
import { Filesystem, Directory, Encoding, ProgressListener } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import write_blob from "capacitor-blob-writer";
import { App } from '@capacitor/app';
import { Share } from '@capacitor/share';
import { HttpService } from './http.service';
import { EntityTypeId, BundleType, FieldName } from '../models';
import { MediaPresignApiService } from './media-presign-api.service';


@Injectable({
  providedIn: 'root'
})
export class FileSystemService {

  constructor(
    private readonly mediaPresignApiService: MediaPresignApiService,
    private httpService: HttpService
  ) { }

  //Generating preSignedUrl Helper
  generatePreSignedUrl(
    item: any,
    entityType = EntityTypeId.PARAGRAPH,
    bundle = BundleType.IMAGE,
    fieldName = FieldName.MEDIA_IMAGE,
  ): Observable<string> {
    return this.mediaPresignApiService.getPreSignedUrl(entityType, bundle, fieldName, item.key || '', item.preSignedToken);
  }

  // Method to start listening for progress events
  startListeningForProgress(handler: any, filename: string): Promise<PluginListenerHandle> {
    const handleProgressEvent: ProgressListener = (progress: any) => {
      console.log(`Downloading ${filename}: ${(progress?.bytes / progress?.contentLength) * 100}%`);
    };

    return handler.addListener('progress', handleProgressEvent);
  }


  async writeSecretFile(path: string, data: string | Blob, directory: Directory = Directory.Library, encoding: Encoding = Encoding.UTF8) {
    const filePath = await Filesystem.writeFile({ path, data, directory, encoding });
    return filePath.uri;
  };

  async getUri(path: string, directory: Directory = Directory.Library, encoding: Encoding = Encoding.UTF8) {
    const filePath = await Filesystem.getUri({ path, directory });
    return filePath.uri;
  };

  async writeBlobFile(path: string, data: Blob, recursive: boolean = true, directory: Directory = Directory.Library, encoding: Encoding = Encoding.UTF8, shouldShare: boolean = false) {
    path = await this.constructFilePath(path);

    await write_blob({
      path, blob: data, directory, recursive,
    });

    const fileUri = await this.getUri(path, directory);

    // Share the file if requested
    if (shouldShare && this.isSharingSupported()) {
      await Share.share({ url: fileUri });
    }

    return fileUri;
  };

  async createDirectory(path: string, directory: Directory = Directory.Library) {
    await Filesystem.mkdir({ path, directory, recursive: true });
  };

  async deleteDirectory(path: string, directory: Directory = Directory.Library) {
    await Filesystem.rmdir({ path, directory, recursive: true });
  };

  async readSecretFile(path: string, directory: Directory = Directory.Library, encoding: Encoding = Encoding.UTF8) {
    const contents = await Filesystem.readFile({ path, directory, encoding });
    return contents;
  };

  async deleteSecretFile(path: string, directory: Directory = Directory.Library) {
    await Filesystem.deleteFile({ path, directory });
  };

  async deleteFilePath(path: string) {
    await Filesystem.deleteFile({ path });
  };

  async readFilePath(path: string) {
    // Here's an example of reading a file with a full file path. Use this to
    // read binary data (base64 encoded) from plugins that return File URIs, such as
    // the Camera. 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/text.txt',
    const contents = await Filesystem.readFile({ path });
    return contents;
  };

  async downloadAndStoreFile(httpProps: HttpOptions, path: string, onProgress: (progress: number) => void, directory: Directory = Directory.Library) {
    path = await this.constructFilePath(path);

    const handleProgressEvent: ProgressListener = (progress: any) => {
      const downloadProgress = (progress?.bytes / progress?.contentLength) * 100;
      onProgress(downloadProgress);
    };

    Filesystem.addListener('progress', handleProgressEvent);

    const downloadOptions = { ...httpProps, path, directory, progress: true, recursive: true };
    return await Filesystem.downloadFile(downloadOptions);
  }

  downloadFile(url: string): Observable<any> {
    return this.httpService.getBlobFile(url);
  }

  async downloadBlob(url: string, onProgress: (progress: number) => void) {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const contentLength = +response.headers.get('Content-Length');
    let downloadedBytes = 0;

    const reader = response.body?.getReader();

    if (!reader) {
      throw new Error('ReadableStream is not supported.');
    }

    const chunks: Uint8Array[] = [];

    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      chunks.push(value);
      downloadedBytes += value.length;

      if (contentLength) {
        const progress = (downloadedBytes / contentLength) * 100;
        onProgress(progress);
      }
    }

    const blob = new Blob(chunks);

    return blob;
  }

  async getFileStat(path: string, directory: Directory = Directory.Library) {
    const aboutFile = await Filesystem.stat({ path, directory });
    return aboutFile;
  }

  /**
   * The function constructFilePath constructs a file path based on the platform and app name.
   * @param {string} path - represents the file path that you want to construct.
   * @returns The returned value is either the original `path` parameter if the platform is iOS (`isIOS` is true), 
   * or it is a concatenation of the `name` obtained from `App.getInfo()` and the `path` parameter if the platform is not iOS.
   */
  private async constructFilePath(path: string): Promise<string> {
    if (!Capacitor.isNativePlatform()) {
      return path;
    }

    const { name } = await App.getInfo();
    const isIOS = Capacitor.getPlatform() === 'ios';

    return isIOS ? path : `${name}/${path}`;
  }

  /**
  * It takes a file URI and returns a web URL
  * @param {string} filePath - The file URI of the file you want to convert.
  * @returns The filePath is being returned as a web url.
  */
  filePathToWebUrl(filePath: string, status?: number): any {
    return Capacitor.convertFileSrc(filePath);
  }

  stringToBlob(content: string, contentType: string = 'application/zip'): Blob {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  //CheckPermission is only applicable to Android phones
  async checkPermission() {
    try {
      const permissions = await Filesystem.checkPermissions();

      if (!permissions) {
        const requestedPermissions = await Filesystem.requestPermissions();
        return requestedPermissions ? true : false;
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  //Check if sharing is supported and plugin is available
  private async isSharingSupported(): Promise<boolean> {
    const isPluginAvailable = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Share');
    const canShare = await Share.canShare();

    return isPluginAvailable && canShare.value;
  }


  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

}
