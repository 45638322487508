<div *ngIf="data?.certificate as certificate" class="certificate-modal">
  <div class="certificate-modal__header">
    <button mat-icon-button class="button button--icon button--transparent" (click)="dialogRef.close()">
      <lib-icon icon="x"></lib-icon>
    </button>
  </div>
  <div class="certificate-modal__body">
    <div
      class="certificate-modal__body--image"
      [cropImage]="certificate?.customCertificate?.mediaImage | preSignedUrl | async" 
      [additionalOptions]="{width: 400}">
    </div>
    <div class="certificate-modal__body--title-wrapper">
      <div class="certificate-modal__body--title">
        {{certificate.title}}
      </div>
      <div *ngIf="certificate.dateOfCompletion" class="certificate-modal__body--subtitle">
        {{'COMMON.granted' | translate}} {{certificate.dateOfCompletion | customDate}}
      </div>
    </div>
  </div>
  <div class="certificate-modal__footer">
    <button *ngIf="data.shareEnabled" mat-icon-button class="button button--icon button--transparent" (click)="share()">
      <lib-icon icon="share-network"></lib-icon>
    </button>
    <button mat-icon-button class="button button--icon button--transparent" (click)="download()">
      <lib-icon *ngIf="!isDownloading; else donwloadIconTemplate" icon="download-simple"></lib-icon>
      <ng-template #donwloadIconTemplate>
        <mat-spinner diameter="24"></mat-spinner>
      </ng-template>
    </button>
    <button mat-flat-button class="button button--primary button--large preview-button" (click)="preview()">
      <lib-icon icon="eye"></lib-icon>
      {{'COMMON.preview' | translate}}
    </button>
  </div>
</div>