import { NgControl } from '@angular/forms';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SettingsService } from '@app/services/settings.service';
import { CookieConsentService, CookieConsentProviderType } from 'library-explorer';
import { filter } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[cookieConsentButton]'
})
export class CookieConsentButtonDirective implements OnInit {
  private initialized = false;

  private type: CookieConsentProviderType;

  private ONE_TRUST_BTN_ID = 'ot-sdk-btn';
  private ONE_TRUST_BTN_CLASS = 'ot-sdk-show-settings';

  constructor(
    private readonly cookieConsentService: CookieConsentService,
    private readonly element: ElementRef) {
  }

  ngOnInit(): void {
    this.initialize();

    fromEvent(this.element.nativeElement, 'click')
      .pipe(
        filter(() => this.initialized && this.type === CookieConsentProviderType.DEFAULT)
      )
      .subscribe(() => {
        this.cookieConsentService.openCookiePreferencesSettings();
      });
  }

  private initialize(): void {
    this.cookieConsentService.initialized()
      .pipe(
        filter(data => data)
      )
      .subscribe(() => {
        this.type = this.cookieConsentService.getProviderType();
        this.initialized = true;

        if (this.type === CookieConsentProviderType.ONE_TRUST) {
          this.initOneTrust();
        }
      });
  }

  private initOneTrust(): void {
    this.element.nativeElement.id = this.ONE_TRUST_BTN_ID;
    this.element.nativeElement.classList.add(this.ONE_TRUST_BTN_CLASS);
  }

}