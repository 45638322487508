import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService, ProfileService } from 'library-explorer';

import { AchievementGroup } from 'src/app/model/achievement-group';
import { LeaderboardGroup } from 'src/app/model/leaderboard-group';
import { LeaderboardNotification } from 'src/app/model/leaderboard-notification';
import { LeaderboardUser } from 'src/app/model/leaderboard-user';
import { Achievement } from '../../model/achievement';
import { AchievementUser } from '../../model/achievement-user';
import { DataResponse } from '../../model/data-response.model';
import { NotificationModel } from '../../model/notification.model';

@Injectable({
  providedIn: 'root'
})
export class GamificationService {
  constructor(
    private readonly httpService: HttpService,
    private readonly profileService: ProfileService
  ) {
  }

  public getAchievementsOfUser(): Observable<DataResponse<AchievementUser>> {
    const userId = this.profileService.getCurrentProfileValue()?.id;
    return this.httpService.get<DataResponse<AchievementUser>>(`user-badges/${userId}`);
  }

  public getUsersOfLeaderboard(queryParams?: { limit: number; offset: number }): Observable<DataResponse<LeaderboardUser>> {
    const { limit , offset } = queryParams;
    const params = new HttpParams()
      .set('limit', limit ? limit.toString() : '10')
      .set('offset', offset ? offset.toString() : '0');

    return this.httpService.get<DataResponse<LeaderboardUser>>('leaderboards', params);
  }

  public getLeaderboardGroups(queryParams?: { limit: number; offset: number }): Observable<DataResponse<LeaderboardGroup>> {
    const { limit , offset } = queryParams;
    const params = new HttpParams()
      .set('limit', limit ? limit.toString() : '10')
      .set('offset', offset ? offset.toString() : '0');

    return this.httpService.get<DataResponse<LeaderboardGroup>>('leaderboards-groups', params);
  }

  public getAchievementGroups(): Observable<AchievementGroup[]> {
    const params = new HttpParams()
      .set('vocabulary', 'badge_category')
      .set('sort', 'weight:ASC');

    return this.httpService.get<DataResponse<AchievementGroup>>('v2/taxonomies', params).pipe(map(res => res.items));
  }


  public getLeaderboardPoints(id: string): Observable<{ points: number, position: number }> {
    return this.httpService.get<{ points: number, position: number }>(`points/${id}`);
  }

  public getAchievementsNotifications(status: number = 0): Observable<Achievement[]> {
    const params = new HttpParams().set('status', `${status}`);
    return this.httpService.get<DataResponse<NotificationModel<Achievement>>>('notifications/achievement', params)
      .pipe(
        map(data => data.items.map(item => item.relatedEntity))
      );
  }

  public getLeaderboardsNotifications(status: number = 0): Observable<LeaderboardNotification[]> {
    const params = new HttpParams().set('status', `${status}`);
    return this.httpService.get<DataResponse<LeaderboardNotification>>('notifications/leaderboard_points', params)
      .pipe(
        map(data => data.items)
      );
  }
}
