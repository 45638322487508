import { OneTrustService } from '@altack/ngx-onetrust';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieConsentAgreement, CookieConsentProviderType, LegalDocumentModel, SettingsModel } from '../../models';
import { CookieConsentProvider } from './cookie-consent-provider';


@Injectable()
export class OneTrustCookieConsentProvider extends CookieConsentProvider {
  public readonly type = CookieConsentProviderType.ONE_TRUST;

  public cookieConsentAgreement: BehaviorSubject<CookieConsentAgreement | null> = new BehaviorSubject<CookieConsentAgreement | null>(null);

  public initialized = new BehaviorSubject(false);

  private readonly TIMEOUT = 1000;

  constructor(private readonly oneTrustService: OneTrustService) {
    super();
  }

  public openCookieConsentDialog(settings: SettingsModel, documents: LegalDocumentModel[]): Promise<void> {
    if (this.initialized?.value) {
      this.togglePreferencesSettings();
      return;
    }

    if (!settings.cookieConsent?.oneTrustDomainScript) {
      return;
    }

    setTimeout(() => {
      this.oneTrustService.loadOneTrust(settings.cookieConsent?.oneTrustDomainScript);
      this.initSubscriptions();
      this.initialized.next(true);
    }, this.TIMEOUT);
  
    return;
  }

  public togglePreferencesSettings(): void {
    this.oneTrustService.oneTrustInstance$().subscribe(data => {
      data.ToggleInfoDisplay();
    });
  }

  private initSubscriptions(): void {
    this.oneTrustService.consentChanged$().subscribe(data => {
      const result = Array.from(data.entries())
        .filter(([_key, value]) => value === true)
        .map(([key]) => key);

      this.cookieConsentAgreement.next({
        categories: result as any,
        consent_date: new Date()
      });
    });
  }
}