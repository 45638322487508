<div class="certificate-preview-modal">
  <div class="certificate-preview-modal__header">
    <button mat-icon-button class="button button--icon button--transparent" (click)="dialogRef.close()">
      <lib-icon icon="x"></lib-icon>
    </button>
  </div>
  <div class="certificate-preview-modal__body">
    <div class="certificate-preview-modal__body--title">
      {{certificate.title}}
    </div>
    <img *ngIf="(certificate?.customCertificate?.mediaImage | preSignedUrl | async) as certificateImage; else loadingTemplate" class="certificate-preview-modal__body--image" [src]="certificateImage" [attr.alt]="certificate.title">
  </div>
</div>

<ng-template #loadingTemplate>
  <mat-spinner diameter="40"></mat-spinner>
</ng-template>