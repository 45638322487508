<div class="carousel-block" *ngIf="block.items && block.items.length" [style.color]="block.textColor">
  <div *ngIf="block.title" class="carousel-block__header" [class.no-title]="!block.title">
    <h4 class="carousel-block__title" [rtl]="block.langcode">{{block.title}}</h4>
    <div *ngIf="block.items.length > 1" class="carousel-block__arrows" [style.color]="block.textColor">
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="swiperComponent?.swiper?.isBeginning" (click)="swiperComponent?.swiper.slidePrev()">
        <lib-icon icon="arrow-left"></lib-icon>
      </button>
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="swiperComponent?.swiper?.isEnd" (click)="swiperComponent?.swiper.slideNext()">
        <lib-icon icon="arrow-right"></lib-icon>
      </button>
    </div>
  </div>
  <app-swiper [slides]="block.items" [options]="swiperOptions">
    <ng-template swiperContent let-item="item">
      <div class="carousel-block__item">
        <a *ngIf="item.link?.uri; else carouselContentTmp" [extendedRouterLink]="item.link.uri" class="carousel-block__item-link">
          <ng-container *ngTemplateOutlet="carouselContentTmp"></ng-container>
        </a>
        <ng-template #carouselContentTmp>
          <img [cropImage]="item.mediaImage | preSignedUrl | async" [attr.alt]="item.mediaImage?.alt">
          <div class="carousel-block__item-content d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-center justify-content-sm-between">
            <h3 [innerHtml]="item.title | safeHtml" [style.color]="block.textColor" [rtl]="item.langcode"></h3>
            <button mat-flat-button *ngIf="item.link?.title" class="button button--primary button--large mt-4 mt-sm-0">
              <span class="button-text" [rtl]="block.langcode">{{item.link.title}}</span>
              <mat-icon class="secondary-color">navigate_next</mat-icon>
            </button>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </app-swiper>
</div>
