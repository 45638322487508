<div class="d-flex align-items-center p-0" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <span class="icon-container mr-3" *ngIf="toastIcon; else customToastType">
    <svg-icon [src]="toastIcon.src" [svgStyle]="{ 'fill': toastIcon.fill }"></svg-icon>
  </span>

  <ng-template #customToastType>
    <div class="toast-icon mr-3">
      <lib-icon icon="check-circle" *ngIf="toastPackage.toastType === 'toast-live-update'" [size]="30"></lib-icon>
    </div>
  </ng-template>

  <div>
    <div *ngIf="title" class="toast-title">{{title}}</div>
    <div class="toast-message">{{message}}</div>
  </div>

  <div 
      *ngIf="options.closeButton" 
      (click)="remove()"
      class="toast-close ml-3"
      [style.visibility]="state.value === 'inactive' ? 'hidden' : 'visible'"
  >
    <lib-icon icon="x" [size]="20" [bold]="true"></lib-icon>
  </div>
</div>
