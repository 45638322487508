import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TaxonomyType } from '@app/model/enums/taxonomy-type.enum';
import { PayableNode } from '@app/model/payable-node';
import { SearchService } from '@app/services/api/search.service';
import { TaxonomyApiService } from '@app/services/api/taxonomy-api.service';
import { PayableNodeNavigationService } from '@app/services/payable-node-navigation.service';
import { BundleType, bundleTypeTextMapPlural, NodeModel, STORAGE_KEYS, StorageKeys, StorageService } from 'library-explorer';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, exhaustMap, finalize, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-quick-search-input',
  templateUrl: './quick-search-input.component.html',
  styleUrls: ['./quick-search-input.component.scss']
})
export class QuickSearchInputComponent implements OnInit, OnDestroy {
  @Input() public bundle: BundleType | BundleType[];

  @Input() public dialogMode = false;
  @Input() public enableLastSearch = false;

  @Input() public placeholder: string;
  
  @Input() public resultTemplate: TemplateRef<any>;
  @Input() public resultsHeaderTemplate: TemplateRef<any>;
  @Input() public enabledSearchByTag = false;

  @Output() public search: EventEmitter<string> = new EventEmitter();
  @Output() public searchByTag: EventEmitter<string> = new EventEmitter();
  @Output() public searchByBundle: EventEmitter<BundleType> = new EventEmitter();
  @Output() public closeSearch: EventEmitter<void> = new EventEmitter();

  public searchControl = new FormControl('');

  public tags = [];
  public tagsLoading = true;

  public data = [];
  public dataGroups: { bundle: string, label: string, items: NodeModel[] } [] = [];
  public dataLoading = true;

  public loadingPlaceholers = new Array(3);

  public expanded = false;

  public availableBundles = [];

  public resultsBundles = [];

  public lastSearches = [];

  public readonly bundles: typeof BundleType = BundleType;

  private unsubscribe: Subject<void> = new Subject();

  private readonly minSearchLength = 3;

  constructor(
    private readonly storageService: StorageService,
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    private readonly payableNodeNavigationService: PayableNodeNavigationService,
    private readonly searchService: SearchService,
    private readonly taxonomyApiService: TaxonomyApiService) { }

  ngOnInit(): void {
    this.loadAvailableBundles();
    this.addSubscriptionsForSearch();
    this.setLastSearchesFromLS();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public submitSearchQuery(): void {
    if (this.searchControl?.value?.length < this.minSearchLength) {
      return;
    }

    this.setCurrentSearchToLS();
    this.search.emit(this.searchControl?.value);
  }

  public navigateToEntity(item: Partial<PayableNode>): void {
    if (item.isLocked) {
      return;
    }
  
    this.setCurrentSearchToLS(item.title);
    this.payableNodeNavigationService.navigate(item)
  }

  public expandSearchPanel(): void {
    this.expanded = this.searchControl?.value?.length >= this.minSearchLength;
  }

  public colapseSearchPanel(): void {
    this.expanded = false;
  }

  public clearSearch(): void {
    this.searchControl.setValue('');
  }

  public closeSearchDialog(): void {
    this.closeSearch.emit();
  }

  public useLastSearchItem(data: { search: string }): void {
    if (!data) {
      return;
    }

    const { search } = data;

    this.searchControl.patchValue(search);
  }

  
  public clearLastSearchItem(event: Event, data: { search: string }): void {
    event.stopPropagation();
  
    if (!data) {
      return;
    }

    this.lastSearches = this.lastSearches.filter(item => item?.search !== data.search);
    this.storageService.setItem(this.storageKeys.LAST_SEARCH, this.lastSearches);
  }


  private loadAvailableBundles(): void {
    this.searchService.getAvailableSearchBundles()
      .subscribe(data => {
        this.availableBundles = data;
        this.resultsBundles = [...this.availableBundles];
      });
  }

  private loadTags(): Observable<any> {
    this.tagsLoading = true;

    const params = new HttpParams({ fromObject: { search: this.searchControl?.value, limit: 3 } });

    return this.taxonomyApiService.getTaxonomiesList(TaxonomyType.PUBLIC_TAGS, params)
      .pipe(
        tap(() => this.tagsLoading = false),
        map(data => this.tags = data)
      );
  }

  private loadData(): Observable<any> {
    this.dataLoading = true;
    this.setData([]);

    const mainSearchRequest = this.bundle
      ? this.searchService.searchBy(this.searchControl?.value, this.bundle, 0, 3)
      : this.searchService.groupSearch(this.searchControl?.value, this.availableBundles, 3);

    return mainSearchRequest
      .pipe(
        tap(() => this.dataLoading = false),
        map(data => {
          const items = data?.items || [];
          this.setData(items);

          this.resultsBundles = this.availableBundles.filter(bundle => items.some(item => item.bundle === bundle));
        })
      );
  }

  private setData(items: NodeModel[]): void {
    this.data = items;

    if (!items || items.length === 0) {
      this.dataGroups = [];
      return;
    }

    if (!this.dialogMode) {
      this.dataGroups = [{ items, bundle: null, label: null }];
      return;
    }

    this.dataGroups = items
      .reduce((groupsList, item) => {
        let group = groupsList.find(group => group.bundle === item.bundle);

        if (!group) {
          group = {
            label: bundleTypeTextMapPlural[item.bundle],
            items: [],
            bundle: item.bundle
          };

          groupsList.push(group);
        }

        group.items.push(item);

        return groupsList;
      }, [])
      .filter(group => group.items.length >= 1)
      .sort((a, b) => this.availableBundles.indexOf(a.bundle) - this.availableBundles.indexOf(b.bundle));
  }

  private addSubscriptionsForSearch(): void {
    const triggerSearch = new BehaviorSubject(false);

    const searchObs = this.searchControl.valueChanges
      .pipe(
        debounceTime(200)
      );
  
      combineLatest([
        searchObs,
        triggerSearch
      ])
      .pipe(
        debounceTime(50),
        exhaustMap(([searchKey]) => {
          this.expanded = searchKey?.length >= this.minSearchLength;

          if (!this.expanded) {
            this.setData([]);
            this.tags = [];
            return of(null);
          }

          return forkJoin([
            this.loadTags(),
            this.loadData()
          ])
          .pipe(
            finalize(() => {
              const changed = searchKey !== this.searchControl?.value;

              if (changed) {
                triggerSearch.next(true);
              }
            })
          )
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private setLastSearchesFromLS(): void {
    this.lastSearches = this.storageService.getItem(this.storageKeys.LAST_SEARCH) || [];
  }

  private setCurrentSearchToLS(search = this.searchControl?.value): void {
    if (!this.enableLastSearch) {
      return;
    }

    if (!search) {
      return;
    }
  
    this.lastSearches = this.lastSearches.filter(item => item.search !== search)
    this.lastSearches.unshift({ search });
    this.lastSearches = this.lastSearches.slice(0, 3);
    this.storageService.setItem(this.storageKeys.LAST_SEARCH, this.lastSearches);
  }
}
