
<swiper-container #swiperContainerRef [class.show-overflow]="swiper?.params?.showOverflow">
  <swiper-slide *ngFor="let slide of slides; index as i">
    <ng-container *ngTemplateOutlet="swiperContent?.template; context: { index: i, item: slide }"></ng-container>
  </swiper-slide>
</swiper-container>

<div class="swiper-buttons-wrapper" *ngIf="swiper?.params?.navigation?.enabled && slides?.length > 1" [class.buttons-wide]="swiper?.params?.navigation?.wide">
  <button #buttonPrev color="primary" mat-fab class="swiper-button swiper-button-prev" [disabled]="swiper?.isBeginning && !swiper?.params?.loop" (click)="swiper.slidePrev()">
    <mat-icon svgIcon="lms-carousel-arrow-left"></mat-icon>
  </button>
  <button #buttonNext color="primary" mat-fab class="swiper-button swiper-button-next" [disabled]="swiper?.isEnd && !swiper?.params?.loop" (click)="swiper.slideNext()">
    <mat-icon svgIcon="lms-carousel-arrow-right"></mat-icon>
  </button>
</div>