import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { NotificationDelivery } from '@app/model/notification-delivery';
import { NotificationsListDialogComponent } from '@app/shared/components/notifications-list-dialog/notifications-list-dialog.component';
import { NotificationApiService } from './api/notification-api.service';
import { LanguageService } from './language.service';
import { AuthService } from './auth.service';
import { NotificationDeliveryType } from '@app/model/enums/notification-delivery-type.enum';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public unreadNotificationsCount: BehaviorSubject<number> = new BehaviorSubject(0);
  public notifications: BehaviorSubject<NotificationDelivery[]> = new BehaviorSubject([]);

  public listDialog: MatDialogRef<NotificationsListDialogComponent>;

  private pollingNotificationInterval: any;
  private readonly pollingTimeout = 30000;

  private dataInitialized = new BehaviorSubject(false);

  constructor(
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly languageService: LanguageService,
    private readonly notificationApiService: NotificationApiService,
    private readonly matDialog: MatDialog,
  ) {
    this.initialize();
  }

  public waitForNotificationsLoaded(): Observable<void> {
    return this.dataInitialized.pipe(
      filter(init => init),
      take(1),
      map(() => null)
    )
  }

  public checkForUnreadNotifications(): void {
    const items = this.notifications.value;
    const undreadCount = items.filter(item => !item.seen)?.length;

    this.unreadNotificationsCount.next(undreadCount);
  }

  public loadNotifications(): void {
    this.notificationApiService.getNotifications()
      .pipe(
        map(data => data['hydra:member'])
      )
      .subscribe((messages: NotificationDelivery[]) => {
        this.notifications.next(messages);
        this.checkForUnreadNotifications();

        this.dataInitialized.next(true);
      });
  }

  public markNotificationAsSeen(notification: NotificationDelivery, type: NotificationDeliveryType = null): void {
    const current = this.notifications.value.find(item => item.notificationId === notification.notificationId) || notification;

    if (current.seen) {
      return;
    }

    this.notificationApiService.markNotificationAsSeen(current.notificationId, type || this.getNotificationDeliveryType(current))
      .subscribe(() => {
        current.seen = true;
        this.checkForUnreadNotifications();
      });
  }

  private getNotificationDeliveryType(notification: NotificationDelivery): NotificationDeliveryType {
    if (notification.regular) {
      return NotificationDeliveryType.REGULAR;
    }

    if (notification.popup) {
      return NotificationDeliveryType.POPUP;
    }

    return NotificationDeliveryType.EMAIL;
  }

  public openListDialog(nativeElement: Element = null): void {
    const dialogState = this.listDialog && this.listDialog.getState();

    if (dialogState === MatDialogState.OPEN) {
      this.closeListDialog();
      return;
    }

    const isDesktop = window.innerWidth > 991;
    const isRtl = this.languageService.isRtl();

    const config = {
      closeOnNavigation: true,
      hasBackdrop: false,
      panelClass: ['sendbird-chat-panel', 'dialog-light-box-shadow'],
      position: {
        top: `${nativeElement?.parentElement.offsetHeight + 12}px`,
        right: isRtl ? null : `60px`,
        left: isRtl ? `60px` : null,
      },
      scrollStrategy: new NoopScrollStrategy()
    };

    if (!isDesktop) {
      Object.assign(config, this.getMobileDialogConfig());
    }

    this.listDialog = this.matDialog.open(NotificationsListDialogComponent, config);
  }

  public closeListDialog(): void {
    if (!this.listDialog) {
      return;
    }

    this.listDialog.close();
    this.listDialog = null;
  }

  public clearNotifications(): void {
    this.notifications.next([]);
    this.unreadNotificationsCount.next(0);
  }

  private initialize(): void {
    this.settingsService.getSettings()
      .pipe(
        take(1),
        filter(settings => settings.mobileApp.pushNotifications?.enabled),
        mergeMap(() => this.authService.isAuthorized())
      )
      .subscribe(isAuth => {
        if (!isAuth) {
          this.clearNotifications();
          this.pollingNotificationInterval && clearInterval(this.pollingNotificationInterval);
          return;
        }

        this.startPollingInterval();
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.closeListDialog()
      });
  }

  private startPollingInterval(): void {
    if (this.pollingNotificationInterval) {
      return;
    }

    this.loadNotifications();

    this.pollingNotificationInterval = setInterval(() => {
      this.loadNotifications();
    }, this.pollingTimeout);
  }

  private getMobileDialogConfig(): MatDialogConfig {
    return {
      hasBackdrop: true,
      position: {
        bottom: '0px',
        left: '0px'
      },
      minWidth: '100vw',
      scrollStrategy: null,
      height: 'calc(100% - 16px)'
    }
  }
}
