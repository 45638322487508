<section 
  class="promotion-block" 
  [cropImage]="block.mediaImage | preSignedUrl | async" 
  [additionalOptions]="{width: 1920}"
  [style.color]="block.textColor"
  [class.has-video]="block.backgroundVideo">

  <ng-container *ngIf="block.backgroundVideo">
    <lib-video 
      [src]="block.backgroundVideo | videoPresign | async"
      class="background-video background-video--absolute position-cover"
      [hideControls]="true"
      [autoplay]="true">
    </lib-video>
  </ng-container>

  <div class="promotion-block__content">
    <div class="promotion-block__left" [class.empty-column]="!hasLeftColumn">
      <div class="promotion-block__wrapper">
        <h1 class="promotion-block__left-head-text" [rtl]="block.langcode">
          {{block.title}}
        </h1>
        <div 
          *ngIf="block.mediaVideo?.uri || block.mediaVideo || block.embedVideo || block.link?.uri" 
          class="promotion-block__left-play"
          (click)="playPromotionVideo()">
          <div class="promotion-block__left-play-button">
          <mat-icon class="primary-color">play_circle_filled</mat-icon>
          </div>
          <div class="ml-3">
            <span class="play-text" [rtl]="block.langcode">{{block.link?.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="promotion-block__right">
      <ng-container *ngIf="classList.length > 0">
        <div class="promotion-block__header flex-column flex-md-row">
          <div class="promotion-block__header-text">
            <h1 [rtl]="block.langcode">{{'DASHBOARD_v2.hi_username' | translate : { userName: userName } }}</h1>
            <h2 [innerHtml]="block.subTitle" [rtl]="block.langcode"></h2>
          </div>
          <mat-form-field appearance="outline" class="mt-4 mt-md-0" *ngIf="classList.length > 1">
            <mat-select [disableRipple]="true" [formControl]="sliderCoursesControl">
              <mat-option *ngFor="let item of classList" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <app-carousel [config]="courseCarouselConfig" type="promotion-course" [items]="sliderCourses"></app-carousel>
        <section *ngIf="!carouselComponent?.swiperComponent?.swiper?.isBeginning || !carouselComponent?.swiperComponent?.swiper?.isEnd" class="row align-items-center mx-lg-5 justify-content-end custom-buttons-wrapper">
          <button color="primary" mat-fab class="carousel__button left" (click)="carouselComponent?.swiperComponent?.swiper.slidePrev()">
            <mat-icon svgIcon="lms-promotion-arrow-left"></mat-icon>
          </button>
          <button color="primary" mat-fab class="carousel__button right" (click)="carouselComponent?.swiperComponent?.swiper.slideNext()">
            <mat-icon svgIcon="lms-promotion-arrow-right"></mat-icon>
          </button>
        </section>
      </ng-container>
    </div>
  </div>
  <div class="scroll-for-more d-none d-md-flex flex-column align-items-center">
    <div class="d-flex align-items-center justify-content-center">
      <span>{{'DASHBOARD_v2.scroll' | translate}}</span>
    </div>
    <div class="scroll-for-more__mouse">
      <div class="scroll-for-more__wheel" [style.background-color]="block.textColor"></div>
    </div>
  </div>
</section>

<div *ngIf="showPromotionVideo" class="video-gallery-modal-wrapper">
  <div class="video-gallery__wrapper" (click)="closeVideoOverlay()">
    <mat-icon class="video-gallery__close-btn">close</mat-icon>
    <div class="video-gallery__player-wrapper">
      <lib-video 
        [embedVideo]="block.embedVideo" 
        [src]="block.mediaVideo | videoPresign | async" 
        [poster]="block.mediaImage | preSignedUrl | async" 
        (click)="stopPropagation($event)"></lib-video>
    </div>
  </div>
  <div class="video-gallery-modal"></div>
</div>