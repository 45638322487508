<section class="banner-block" *ngIf="block && !hidden" [class.normal-width]="block.widthMode === 'normal'" [class.pointer-events-none]="!block.link || !block.link.uri">
  <a class="image" [class.image-link]="block.link?.uri" [extendedRouterLink]="block.link?.uri" #imageWrapperEl>
    <ng-container *ngIf="block.backgroundVideo || block.backgroundVideoPhone">
      <ng-container *ngIfMediaQuery="'(min-width: 640px)'; else mobileVideoTmp">
        <lib-video 
          *ngIf="block.backgroundVideo"
          [src]="block.backgroundVideo | videoPresign | async"
          class="background-video position-cover"
          [hideControls]="true"
          [autoplay]="true">
        </lib-video>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="block.mediaImage || block.mediaImagePhone">
      <ng-container *ngIfMediaQuery="'(min-width: 640px)'; else mobileImageTmp">
        <img 
          *ngIf="block.mediaImage && !block.backgroundVideo"
          [cropImage]="block.mediaImage | preSignedUrl | async" 
          [additionalOptions]="{width: 1920}" 
          [attr.alt]="block.mediaImage.alt">
      </ng-container>
    </ng-container>

    <ng-template #mobileImageTmp>
      <ng-container *ngIf="(!block.backgroundVideoPhone && (block.mediaImagePhone || (!block.backgroundVideo && block.mediaImage))) as mobileImage">
        <img 
          [cropImage]="mobileImage | preSignedUrl | async" 
          [additionalOptions]="{width: 600}" 
          [attr.alt]="mobileImage.alt">
      </ng-container>
    </ng-template>

    <ng-template #mobileVideoTmp>
      <ng-container *ngIf="(block.backgroundVideoPhone || (!block.mediaImagePhone && block.backgroundVideo)) as mobileVideo">
        <lib-video 
          [src]="mobileVideo | videoPresign | async"
          class="background-video position-contain"
          [hideControls]="true"
          [autoplay]="true">
        </lib-video>
      </ng-container>
    </ng-template>
  </a>
  
  <div 
    class="block-container"
    *ngIf="block.text || block.link"
    #contentWrapperEl
    [class.pointer-events-none]="block.link?.uri && (block.mediaImage || block.mediaImagePhone)">
    <div class="content description py-0 py-sm-2 px-sm-0 ck-text" *ngIf="block.text" [innerHtml]="block.text | safeHtml" [rtl]="block.langcode"></div>
    <div class="content button-wrapper py-0 py-sm-2 px-sm-0" *ngIf="block.link && block.link.title">
      <button mat-flat-button *ngIf="!block.link.isExternal" class="button button--primary button--large" [routerLink]="[block.link.uri]">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </button>
      <a mat-flat-button *ngIf="block.link.isExternal" class="button button--primary button--large" [href]="block.link.uri" target="_blank">
        <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
      </a>
      <ng-template #buttonContent>
        <span class="button-text">{{block.link.title}}</span>
        <mat-icon>navigate_next</mat-icon>
      </ng-template>
    </div>
  </div>
</section>
