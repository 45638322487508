import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[swiperContent]'
})
export class SwiperContentDirective {

  constructor(public template: TemplateRef<any>) { }

}
