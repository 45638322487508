import { AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from '@app/services/settings.service';
import { finalize, take } from 'rxjs/operators';
import { SettingsProfileFieldType } from 'library-explorer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserCertificate } from '@app/model/user-certificate.model';
import { Achievement } from '@app/model/achievement';
import { LeaderboardNotification } from '@app/model/leaderboard-notification';
import { LessonService } from '@app/services/api/lesson.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reward-modal',
  templateUrl: './reward-modal.component.html',
  styleUrls: ['./reward-modal.component.scss']
})
export class RewardModalComponent implements OnInit, AfterViewInit {

  @ViewChild('feedbackPageTemplate') feedbackPageTemplate: TemplateRef<any>;
  @ViewChild('pointsPageTemplate') pointsPageTemplate: TemplateRef<any>;
  @ViewChild('badgePageTemplate') badgePageTemplate: TemplateRef<any>;
  @ViewChild('certificatePageTemplate') certificatePageTemplate: TemplateRef<any>;

  public currentPageIndex = 0;
  public ratings = new Array(5).fill(0).map((_, index) => index + 1);
  public feedbackForm: FormGroup;
  public feedbackSettings: any = {};
  public formVisible = false;
  public isContinueLoading = false;
  public pages: {type: string, template: TemplateRef<any>, data: any}[] = [];

  public readonly fieldTypes: typeof SettingsProfileFieldType = SettingsProfileFieldType;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: {certificates: UserCertificate[], achievements: Achievement[], points: LeaderboardNotification[], showFeedback?: boolean, lessonId?: string},
    private readonly dialogRef: MatDialogRef<RewardModalComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly settingsService: SettingsService,
    private readonly lessonService: LessonService 
  ) { }

  ngOnInit(): void {
    this.initFeedbackForm();
    this.initSettings();
  }

  ngAfterViewInit(): void {
    this.initData();
  }

  public selectPage(index: number): void {
    this.currentPageIndex = index;
  }

  public nextPage(): void {
    const nextLogic = () => {
      if (this.currentPageIndex >= this.pages.length - 1) {
        this.dialogRef.close();
        return;
      }
  
      this.currentPageIndex++;
    }

    if (this.pages[this.currentPageIndex].type === 'feedback' && (this.feedbackSettings.ratingEnabled || this.feedbackSettings.fields?.length) && !this.feedbackForm.invalid) {
      this.sendFeedback().pipe(
        finalize(() => nextLogic())
      ).subscribe();
      return;
    }

    nextLogic();
  }

  public setRating(rate: number): void {
    this.feedbackForm.patchValue({
      rate
    });
  }

  public showForm(): void {
    this.formVisible = true;
  }

  public navigateInNewTab(path: string): void {
    window.open(path, '_blank');
  }

  private initFeedbackForm(): void {
    this.feedbackForm = this.formBuilder.group({
      rate: [null, Validators.required],
    });
  }

  private initSettings(): void {
    this.settingsService.getSettings().pipe(
      take(1)
    ).subscribe(settings => {
      this.feedbackSettings = settings.feedback || {};
      this.formVisible = !this.feedbackSettings?.showFormButtonEnabled
      this.addFieldsToForm();
    })
  }

  private addFieldsToForm(): void {
    if (!this.feedbackSettings?.fields?.length) {
      return;
    }

    this.feedbackSettings.fields.forEach(field => {
      const control = this.formBuilder.control('');
      this.feedbackForm.addControl(field.id, control);
    });
  }

  private initData(): void {
    const points = this.dialogData.points.reduce((prev, item) => prev + (+item.earnedPoints), 0);
    const currentPosition = this.dialogData.points.reduce((prev, item) => {prev = item.currentPosition < prev ? item.currentPosition : prev; return prev}, Infinity);

    this.pages = [];

    if (this.dialogData.showFeedback) {
      this.pages.push({
        type: 'feedback',
        template: this.feedbackPageTemplate,
        data: {}
      })
    }
    
    if (points) {
      this.pages.push({
        type: 'points',
        template: this.pointsPageTemplate,
        data: {
          points,
          currentPosition
        }
      })
    }

    this.dialogData.achievements.forEach(achievement => {
      this.pages.push({
        type: 'badge',
        template: this.badgePageTemplate,
        data: achievement
      })
    });

    this.dialogData.certificates.forEach(cert => {
      this.pages.push({
        type: 'certificate',
        template: this.certificatePageTemplate,
        data: cert
      })
    });
  }

  private sendFeedback(): Observable<any> {
    this.isContinueLoading = true;
    const { rate, ...fieldsObject } = this.feedbackForm.value;
    const fields = Object.keys(fieldsObject)?.reduce((prev, key) => {
      prev = [...prev, { id: key, value: fieldsObject[key] }];
      return prev;
    }, []);

    return this.lessonService.sendFeedback(this.dialogData.lessonId, {rate, fields}).pipe(
      finalize(() => {
        this.isContinueLoading = false;
      })
    )
  }

}
