<div
  class="content-card class-card body-under-media"
  tabindex="0"
  [ngClass]="[orientation || '', 'bundle-' + data.bundle]"
  [class.locked]="data.isLocked"
  [class.disabled]="!data.active"
  [attr.data-cy]="'class-card-' + data.id"
  (click)="cardClick.emit($event)">
  <div class="content-card__media">
    <div class="content-card__media--image"
      [placeholderImage]="!data?.mediaImage && data.bundle"
      [cropImage]="(data?.mediaImage | preSignedUrl | async) || data.image"
      [crop]="data.mediaImage?.crop"
      imageWidth="400">
    </div>
    <div class="content-card__badges">
      <div
        *ngIf="data.isLocked"
        class="content-card__badges--item badge-dark"
        [matTooltip]="data.selectiveLockingItems?.length
          ? ('COMMON.selective_locked_list' | translate:{content: selectiveLockingItems})
          : ('ERRORS.content_locked' | translate)">
        <lib-icon icon="lock-simple" [size]="16"></lib-icon> {{'MODULE_LESSON_CARD.locked' | translate}}
      </div>
      <div *ngIf="data.progress" class="content-card__badges--item badge-green">
        <lib-icon icon="student" [size]="16"></lib-icon> {{'COMMON.enrolled' | translate}}
      </div>
    </div>
  </div>
  <div class="content-card__body">
    <div *ngIf="showType" class="content-card__body--label">
      <div class="content-card__body--label-item text-uppercase">
        {{bundleTypeTextMap[data.bundle] | translate}}
      </div>
    </div>
    <div class="content-card__body--title">{{data.title}}</div>
    <div *ngIf="data.coursesCount" class="content-card__body--footer">
      <div class="content-card__body--footer-item">
        <lib-icon icon="stack" [size]="16"></lib-icon> {{'COMMON.n_courses' | translate:{count: data.coursesCount} }}
      </div>
      <div *ngIf="data.leaderboardPoints && leaderboardEnabled" [matTooltip]="'COMMON.content_complete_points' | translate:{points: data.leaderboardPoints}" class="content-card__body--footer-item">
        <lib-icon icon="currency-eth" [size]="16"></lib-icon> {{data.leaderboardPoints}} {{'LEADERBOARD.pts' | translate | lowercase}}
      </div>
      <div *ngIf="data.achievements?.length" [matTooltip]="'COMMON.content_complete_achievements' | translate:{count: data.achievements?.length, content: achievementsTitle}" class="content-card__body--footer-item">
        <lib-icon icon="medal" [size]="16"></lib-icon> {{'COMMON.achievement' | translate}}
      </div>
      <div *ngIf="data.certifications?.length" [matTooltip]="'COMMON.content_complete_certificate' | translate:{ certificate: certificationsTitle }" class="content-card__body--footer-item">
        <lib-icon icon="certificate" [size]="16"></lib-icon> {{'COMMON.certificate' | translate}}
      </div>
    </div>
  </div>
</div>