import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { PlaceholderService } from '../services/placeholder.service';
import { BundleType } from '../models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[placeholderImage]'
})
export class PlaceholderImageDirective implements OnDestroy {

  @Input() set placeholderImage(bundle: BundleType) {
    this.setPlaceholderBackground(bundle);
  }

  private unsubscribe = new Subject<void>();

  constructor(
    private readonly placeholderService: PlaceholderService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  private setPlaceholderBackground(bundle: BundleType): void {
    if (!bundle) {
      return;
    }
  
    this.placeholderService.getPlaceholder(bundle).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(placeholder => {
      const isImgElement = this.el.nativeElement.tagName === 'IMG';
      if (isImgElement) {
        this.renderer.setAttribute(this.el.nativeElement, 'src', placeholder.url);
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${placeholder.url})`);
      }

      if (!placeholder.isCustom) {
        this.renderer.addClass(this.el.nativeElement, 'llab-default-placeholder');
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
