import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperComponent } from '@app/shared/components/swiper/swiper.component';

@Component({
  selector: 'app-carousel-block',
  templateUrl: './carousel-block.component.html',
  styleUrls: ['./carousel-block.component.scss']
})
export class CarouselBlockComponent implements OnInit {

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  @Input() public block: any;

  public swiperOptions = {
    loop: true,
    breakpoints: {
      0: {
        navigation: {
          enabled: false
        },
      },
      992: {
        navigation: {
          enabled: true
        }
      }
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
