import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { BundleType } from 'library-explorer';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { QuickSearchInputComponent } from '../quick-search-input/quick-search-input.component';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit, OnDestroy {
  @ViewChild(QuickSearchInputComponent) public quickSearchInput: QuickSearchInputComponent;

  @HostBinding('style.height.px') dialogMobileHeight: number;

  private unsubscribe: Subject<void> = new Subject();

  private desktopMediaQuery = window.matchMedia('(min-width: 992px)');
  private resizeListener: () => void;
  private readonly DIALOG_HEIGHT_OFFSET = 20;

  constructor(
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<SearchDialogComponent>) { }

  ngOnInit(): void {
    this.addRouteSubscriptions();
    this.addKeyboardListeners();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.removeKeyboardListeners();
    this.removeViewportResizeListener();
  }

  private addKeyboardListeners(): void {
    if (!this.isCapacitorKeyboardPluginAvailable()) {
      this.addViewportResizeListener();
      return;
    }

    Keyboard.addListener('keyboardDidShow', (info: { keyboardHeight: number }) => {
      const availableHeight = window.innerHeight - info.keyboardHeight;
      this.dialogMobileHeight = availableHeight - this.DIALOG_HEIGHT_OFFSET;
    });
  
    Keyboard.addListener('keyboardDidHide', () => {
      this.dialogMobileHeight = window.innerHeight - this.DIALOG_HEIGHT_OFFSET;
    });
  }
  
  private removeKeyboardListeners(): void {
    if (!this.isCapacitorKeyboardPluginAvailable()) {
      return;
    }

    Keyboard.removeAllListeners();
  }

  private addViewportResizeListener(): void {
    if (this.desktopMediaQuery.matches) {
      return;
    }

    if (this.resizeListener || !window.visualViewport) {
      return;
    }
  
    this.dialogMobileHeight = window.visualViewport.height - this.DIALOG_HEIGHT_OFFSET;
  
    this.resizeListener = () => {
      this.dialogMobileHeight = window.visualViewport.height - this.DIALOG_HEIGHT_OFFSET;
    };
  
    window.visualViewport.addEventListener('resize', this.resizeListener);
  }

  private removeViewportResizeListener(): void {
    if (!this.resizeListener) {
      return;
    }
    
    window.visualViewport.removeEventListener('resize', this.resizeListener);
    this.resizeListener = null;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public navigateToSearch(bundle: BundleType = null): void {
    const searchValue = this.quickSearchInput?.searchControl?.value?.trim() || null;

    this.router.navigate(['/search'], { queryParams: {
      search: searchValue, bundle
    }});
  }

  private addRouteSubscriptions(): void {
    this.router.events
     .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe),
     )
     .subscribe(() => {
       this.dialogRef.close();
     });
  }

  private isCapacitorKeyboardPluginAvailable(): boolean {
    return Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Keyboard');
  }

}
