<ng-container *ngIf="svgIconUrl; else defaultTmp">
  <lib-svg-icon [url]="svgIconUrl" [size]="size" (loadFailed)="loadFailed()"></lib-svg-icon>
</ng-container>

<ng-template #defaultTmp>
  <i 
    class="icon ph ph-{{icon}}" 
    [class.ph-bold]="bold" 
    [class.ph-duotone]="duotone" 
    [class.ph-fill]="fill" 
    [style.fontSize.px]="size">
  </i>
</ng-template>
