import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from 'library-explorer';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CourseDTO } from 'src/app/model/courseDTO.model';
import { ClassService } from 'src/app/services/api/class.service';
import { CourseService } from 'src/app/services/api/course.service';
import { DashboardService } from 'src/app/services/api/dashboard.service';
import { CarouselComponent } from '@app/components/commons/carousel/carousel.component';
import { PayableNodeNavigationService } from '@app/services/payable-node-navigation.service';
import { SettingsService } from '@app/services/settings.service';

@Component({
  selector: 'app-dashboard-highlights-block',
  templateUrl: './dashboard-highlights-block.component.html',
  styleUrls: ['./dashboard-highlights-block.component.scss']
})
export class DashboardHighlightsBlockComponent implements OnInit, OnDestroy {

  @ViewChild('startedCoursesCarousel') startedCoursesCarousel: CarouselComponent;

  @Input() block: any;

  public startedCourseList: CourseDTO[];
  public exploreItemList: any[];
  public nextRecommendedItem: any;
  public recommended: any;
  public nextRecommendedItemQueryParams: any;
  public nextRecommendedItemRouterLink: string;
  public itemsToLearn: any[];
  public learningPathCombined: any[];
  public completedItem: any;
  public completedItemQueryParams: any;
  public completedItemRouterLink: string;
  public selectedItemId = '';
  public userName: string;
  public carouselConfig = {
    navigation: {
      enabled: true,
      wide: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        showOverflow: true,
        navigation: {
          enabled: false
        },
      },
      768: {
        slidesPerView: 2,
        showOverflow: false,
      },
      992: {
        navigation: {
          enabled: true
        }
      },
      1200: {
        slidesPerView: 1
      }
    }
  }

  public skipModule: boolean;
  public allModulesLabel = 'All';
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly profileService: ProfileService,
    private readonly courseService: CourseService,
    private readonly classService: ClassService,
    private readonly dashboardService: DashboardService,
    private readonly settingsService: SettingsService,
    private readonly payableNodeNavigationService: PayableNodeNavigationService
  ) { }

  ngOnInit() {
    this.userName = this.profileService.getCurrentProfileValue()?.name;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public continueCourse(): void {
    if (!this.startedCoursesCarousel) {
      return;
    }

    const currentIndex = this.startedCoursesCarousel.swiperComponent.swiper?.realIndex;
    const course = this.startedCourseList[currentIndex];
    this.payableNodeNavigationService.navigate(course);
  }

  private loadData(): void {
    this.courseService.getStartedCourses(null, true)
      .subscribe(res => {
        setTimeout(() => {
          this.startedCourseList = res;
        });
      });

    this.settingsService.getSettings()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.skipModule = data.system.skip_module;
        this.skipModule ? this.getAllClasses() : this.getAllCourses();
      });

    forkJoin([
      this.dashboardService.getNextLearningItem(),
      this.dashboardService.getInProgressLearningItems()
    ])
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(([nextRecommended, inProgress]) => {
        this.nextRecommendedItem = nextRecommended;
        this.nextRecommendedItem.isNextRecommended = true;
        this.itemsToLearn = inProgress;

        if (Array.isArray(this.nextRecommendedItem) && this.nextRecommendedItem.length === 0) {
          this.nextRecommendedItem = null;
        }

        if (this.nextRecommendedItem) {
          this.nextRecommendedItemQueryParams = {
            courseID: this.nextRecommendedItem.parentCourseId
          };
          if (this.nextRecommendedItem.bundle === 'module') {
            this.nextRecommendedItemQueryParams.moduleID = this.nextRecommendedItem.uuid;
            this.nextRecommendedItemRouterLink = '/lessons';
            this.recommended = { count: this.nextRecommendedItem.lessonsCount, text: 'PROGRESS.lessons_inside' };
          } else if (this.nextRecommendedItem.bundle === 'lesson') {
            this.nextRecommendedItemQueryParams.lessonID = this.nextRecommendedItem.uuid;
            this.nextRecommendedItemRouterLink = '/chapters';
            this.recommended = { count: this.nextRecommendedItem.slides && this.nextRecommendedItem.slides.length, text: 'PROGRESS.chapters_inside' };
          }
        }

        this.learningPathCombined = this.nextRecommendedItem
          ? [this.nextRecommendedItem, ...this.itemsToLearn]
          : this.itemsToLearn;
      });

    this.dashboardService.getCompletedLearningItems()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(res => {
        this.completedItem = res[0];
        if (this.completedItem) {
          this.completedItemQueryParams = {
            courseID: this.completedItem.parentCourseId
          };
          if (this.completedItem.bundle === 'module') {
            this.completedItemQueryParams.moduleID = this.completedItem.uuid;
            this.completedItemRouterLink = '/lessons';
          } else if (this.completedItem.bundle === 'lesson') {
            this.completedItemQueryParams.lessonID = this.completedItem.uuid;
            this.completedItemRouterLink = '/chapters';
          }
        }
      });
  }

  private getAllCourses(): void {
    this.courseService.getAllCourses()
      .pipe(
        map(list => list.filter(item => !item.payable || item.isPaid)),
        takeUntil(this.unsubscribe)
      )
      .subscribe(res => this.exploreItemList = res);
  }

  private getAllClasses(): void {
    this.classService.getAllClasses()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(res => this.exploreItemList = res);
  }


}
