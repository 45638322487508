import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BundleType, EntityTypeId, FieldName, PreSignRequestData, SlideType } from '../models';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MediaPresignApiService {
  constructor(
    private readonly httpService: HttpService,
    private readonly storage: StorageMap
  ) { }

  public getPreSignedUrl(
    entityTypeId: EntityTypeId,
    bundle: BundleType | SlideType,
    fieldName: FieldName,
    key: string,
    preSignedToken?: string
  ): Observable<string> {
    const data: any = {
      entityTypeId,
      bundle,
      fieldName,
      key
    };

    if (preSignedToken) {
      data.preSignedToken = preSignedToken;
    }

    const fetch$ = this.httpService.post<{ url: string }>(`get-pre-signed-url`, data);
    return this.presignedUrlFromCache(data, fetch$);
  }

  public getPreSignedUrlCookies(
    entityTypeId: EntityTypeId,
    bundle: BundleType | SlideType,
    fieldName: FieldName,
    key: string,
    preSignedToken?: string
  ): Observable<string> {
    const data: any = {
      entityTypeId,
      bundle,
      fieldName,
      key
    };

    if (preSignedToken) {
      data.preSignedToken = preSignedToken;
    }

    return this.httpService.post<{ url: string }>('get-pre-signed-cookies', data, true)
      .pipe(
        map(res => res?.url)
      );
  }

  public getPreSignedUrlMulti(items: PreSignRequestData[]): Observable<string[]> {
    return this.httpService.post<{ url: string }[]>(`multipart-pre-signed-url`, { items }, true)
      .pipe(
        map(items => {
          return items.map(item => item.url)
        })
      )
  }

  private presignedUrlFromCache(data: any, fetch$: Observable<{url: string}>): Observable<string> {  
    // temopary disabled caching presign requets
    return fetch$.pipe(
      map((res: { url: string }) => res.url)
    );

    // const getQueryParamFromUrl = (url: string, queryParam: string) => {
    //   try {
    //     const urlBase = new URL(url);
    //     return urlBase.searchParams.get(queryParam);
    //   } catch(e) {
    //     return null;
    //   }
    // }

    // const isUrlActive = (cachedDate: { url: string }): boolean => {
    //   if (!cachedDate) {
    //     return false;
    //   }

    //   const { url } = cachedDate;

    //   const now = new Date().getTime();
    //   const expirationBuffer = 10 * 1000;

    //   const [xDateParam, xExpiredParam, expiresParam] = [
    //     getQueryParamFromUrl(url, 'X-Amz-Date'), 
    //     getQueryParamFromUrl(url, 'X-Amz-Expires'), 
    //     getQueryParamFromUrl(url, 'Expires')
    //   ];

    //   if (+expiresParam) {
    //     return new Date(+expiresParam * 1000).getTime() > (now + expirationBuffer);
    //   }

    //   const expirationTime = +(xExpiredParam || 0) * 1000;
    //   const requestDateTime = moment(xDateParam).valueOf();

    //   return requestDateTime + expirationTime > (now + expirationBuffer);
    // };

    // fetch$ = fetch$.pipe(
    //   tap((res) => {
    //     this.storage.set(data.key, res).subscribe()
    //   })
    // );

    // return this.storage.get(data.key, { type: 'object', properties: { url: { type: 'string' } } }).pipe(
    //   mergeMap((storageRes: any) => {
    //     return isUrlActive(storageRes) ? of(storageRes) : fetch$
    //   }),
    //   map((res: { url: string }) => res.url)
    // );
  }
}
