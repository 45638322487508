<div
  class="content-card lesson-card body-under-media"
  tabindex="0"
  [ngClass]="['layout-' + layout, 'bundle-' + data.bundle]"
  [class.locked]="data.isLocked"
  [class.disabled]="!data.active"
  [attr.data-cy]="data.bundle + '-card-' + data.id"
  (click)="cardClick.emit($event)">
  <div class="content-card__media">
    <div class="content-card__media--image"
      [placeholderImage]="!data?.mediaImage && data.bundle"
      [cropImage]="(data?.mediaImage | preSignedUrl | async) || data.image"
      [crop]="data.mediaImage?.crop"
      imageWidth="400">
    </div>
    <div class="content-card__badges">
      <div *ngIf="data.isLocked" class="content-card__badges--item badge-dark"><lib-icon icon="lock-simple" [size]="16"></lib-icon> {{'MODULE_LESSON_CARD.locked' | translate}}</div>
      <div *ngIf="data.isNew && data?.progress !== 100" class="content-card__badges--item badge-blue">{{'COMMON.new' | translate}}</div>
      <div *ngIf="data.nonMandatory" class="content-card__badges--item badge-orange">
        <mat-icon svgIcon="lms-branch"></mat-icon> {{'COMMON.optional' | translate | titlecase}}
      </div>
    </div>
    <div *ngIf="!data.isLocked" class="content-card__media--button">
      <button mat-flat-button class="button button--primary">
        <lib-icon [icon]="data.progress === 100 ? 'arrow-clockwise' : 'play'"></lib-icon>
        <ng-container *ngIf="data.progress === 100">{{'COMMON.start_again' | translate}}</ng-container>
        <ng-container *ngIf="data.progress && data.progress !== 100">{{'COMMON.continue' | translate}}</ng-container>
        <ng-container *ngIf="!data.progress">{{'COMMON.start' | translate}}</ng-container>
      </button>
    </div>
    <div *ngIf="data.progress && !hideProgress" class="content-card__media--progress progress-indicator">
      <mat-progress-bar mode="determinate" [value]="data.progress"></mat-progress-bar>
    </div>
  </div>
  <div class="content-card__body">
    <div class="content-card__body--label">
      <div class="content-card__body--label-item text-uppercase">
        <lib-icon [icon]="data.bundle === bundleType.LESSON ? 'book-open-text' : 'game-controller'" [size]="16"></lib-icon>
        {{bundleTypeTextMap[data.bundle] | translate}}
      </div>
      <div *ngIf="data.time" class="content-card__body--label-item">
        <lib-icon icon="clock" [size]="16"></lib-icon> {{data.time | minutesToTime : 'short' }}
      </div>
      <div *ngIf="data.leaderboardPoints && leaderboardEnabled" [matTooltip]="'COMMON.content_complete_points' | translate:{points: data.leaderboardPoints}" class="content-card__body--label-item">
        <lib-icon icon="currency-eth" [size]="16"></lib-icon> {{data.leaderboardPoints}} {{'LEADERBOARD.pts' | translate | lowercase}}
      </div>
      <div *ngIf="data.achievements?.length" [matTooltip]="'COMMON.content_complete_achievements' | translate:{count: data.achievements?.length, content: achievementsTitle}" class="content-card__body--label-item">
        <lib-icon icon="medal" [size]="16"></lib-icon> {{'COMMON.achievement' | translate}}
      </div>
      <div *ngIf="data.certifications?.length" [matTooltip]="'COMMON.content_complete_certificate' | translate:{ certificate: certificationsTitle }" class="content-card__body--label-item">
        <lib-icon icon="certificate" [size]="16"></lib-icon> {{'COMMON.certificate' | translate}}
      </div>
      <div *ngIf="data.progress === 100" class="content-card__body--label-item completed-indicator">
        <lib-icon icon="check-circle" [fill]="true"></lib-icon> {{'MODULE_LESSON_CARD.completed' | translate}}
      </div>
    </div>
    <div class="content-card__body--title">{{data.title}}</div>
  </div>
</div>