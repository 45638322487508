<section class="testimonials" *ngIf="block" [style.color]="block.textColor">
  <div class="content block-container">
    <h1 *ngIf="block.title" [innerHtml]="block.title | safeHtml" [rtl]="block.langcode"></h1>
    <app-swiper [slides]="block.items" [options]="swiperOptions">
      <ng-template swiperContent let-slide="item">
        <div class="slide">
          <div class="testimonial-item">
            <div class="image" [cropImage]="slide.mediaImage | preSignedUrl | async" [additionalOptions]="{width: 100}"></div>
            <div class="text-wrapper">
              <div class="description ck-text" [innerHtml]="slide.text | safeHtml" [rtl]="slide.langcode"></div>
              <div class="name" [class.primary-color]="!block.textColor" *ngIf="slide.author">- {{slide.author}}</div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-swiper>
    <div class="d-none d-lg-flex carousel-buttons-wrapper" *ngIf="!swiperComponent?.swiper?.isBeginning || !swiperComponent?.swiper?.isEnd">
      <button color="primary" [style.color]="block.textColor" [style.border-color]="block.textColor" mat-fab class="carousel-button left" [class.disabled]="swiperComponent?.swiper?.isBeginning" (click)="swiperComponent?.swiper.slidePrev()">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button color="primary" [style.color]="block.textColor" [style.border-color]="block.textColor" mat-fab class="carousel-button right" [class.disabled]="swiperComponent?.swiper?.isEnd" (click)="swiperComponent?.swiper.slideNext()">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</section>