import { Injectable, Injector } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppTrackingStatusResponse, AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { CookieCategory, CookieConsentAgreement, CookieConsentProviderType, LegalDocumentModel, SettingsModel } from '../../models';
import { CookieConsentProvider } from './cookie-consent-provider';
import { DefaultCookieConsentProvider } from './default-cookie-consent-provider';
import { OneTrustCookieConsentProvider } from './one-trust-cookie-consent-provider';


@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  private provider: CookieConsentProvider;

  private cookieConsentAgreement: BehaviorSubject<CookieConsentAgreement | null> = new BehaviorSubject<CookieConsentAgreement | null>(null);

  private get isBot() {
    return navigator && ((navigator.userAgent && /bot|crawl|spider|slurp|teoma/i.test(navigator.userAgent)) || navigator.webdriver);
  }

  private cookieDefault = {
    categories: Object.values(CookieCategory),
    level: Object.values(CookieCategory),
    consent_date: new Date()
  };

  private providerInitialized = new BehaviorSubject(false);

  constructor(private readonly injector: Injector) {}

  public getProviderType(): CookieConsentProviderType {
    return this.provider?.type;
  } 

  public initialized(): Observable<boolean> {
    return this.providerInitialized.pipe(
      filter(init => init),
      take(1),
      mergeMap(() => this.provider?.initialized.asObservable())
    );
  }

  public getCookieConsentAgreement(): Observable<CookieConsentAgreement | null> {    
    return this.cookieConsentAgreement.asObservable();
  }

  public isCookieConsentAgreed(category: CookieCategory): boolean {
    const cookieConsentAgreement = this.cookieConsentAgreement?.value;
    if (!cookieConsentAgreement) {
      return false;
    }

    return cookieConsentAgreement.categories.indexOf(category) !== -1;
  }

  public async init(settings: SettingsModel, documents: LegalDocumentModel[] = []): Promise<void> {
    const isIosPlatform = Capacitor.getPlatform() === 'ios';

    if (isIosPlatform) {
      const trackingTransparecy = await this.checkTrackingTransparency();

      if (trackingTransparecy.status === 'authorized') {
        this.cookieConsentAgreement.next(this.cookieDefault);
      }

      return;
    }

    if (this.isBot) {
      // Cypress
      this.cookieConsentAgreement.next(this.cookieDefault);

      return;
    }

    this.initProvider(settings);

    this.provider.openCookieConsentDialog(settings, documents);
  }

  public openCookiePreferencesSettings(): void {
    this.provider.togglePreferencesSettings();
  }

  private async checkTrackingTransparency(): Promise<AppTrackingStatusResponse> {
    let data = await AppTrackingTransparency.getStatus();

    if (data.status !== 'authorized') {
      data = await AppTrackingTransparency.requestPermission();
    }

    return data;
  }

  private initProvider(settings: SettingsModel): void {
    switch (settings.cookieConsent?.provider) {
      case CookieConsentProviderType.ONE_TRUST:
        this.provider = this.injector.get(OneTrustCookieConsentProvider);
        break;
      default:
        this.provider = this.injector.get(DefaultCookieConsentProvider);
    }

    this.provider.cookieConsentAgreement.subscribe(data => this.cookieConsentAgreement.next(data));
    this.providerInitialized.next(true);
  }

}