import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomDatePipe, DateFormatTypeLocales } from 'library-explorer';

@Pipe({
  name: 'dateDiff',
  pure: false
})
export class DateDiffPipe implements PipeTransform {

  constructor(
    private readonly customDatePipe: CustomDatePipe,
    private readonly translateService: TranslateService) { }

  transform(value: any): string {
    if (!value) {
      return '';
    }

    const now = new Date().setHours(23, 59, 59);
    const date = new Date(value);
    const diffTime = Math.abs(now - date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;

    switch (diffDays) {
      case 0:
        const diffMinutes = Math.ceil(diffTime / (1000 * 60));
        return diffMinutes < 60 ?
          this.translateService.instant('CHAT.min_ago', { value: diffMinutes })
          : this.customDatePipe.transform(date.toString(), {eu: 'HH:mm', us: 'hh:mm a'} as DateFormatTypeLocales);
      case 1: {
        return this.translateService.instant('CHAT.yesterday');
      }
      default: {
        return this.customDatePipe.transform(date.toString());
      }
    }
  }
}
