import { Inject, Injectable } from '@angular/core';
import { PAGE_BLOCK_KEYS_MAP, ProfileService } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class PageBlockService {

  constructor(
    @Inject(PAGE_BLOCK_KEYS_MAP) private readonly pageBlockKeysMap: any,
    private readonly profileService: ProfileService
  ) { }

  public parseBlockKeys(text: string): string {
    if (!text) {
      return '';
    }
  
    let result = text;
    const user = this.profileService.getCurrentProfileValue();

    Object.keys(this.pageBlockKeysMap).forEach(key => {
      const regexp = new RegExp(`\\[${key}\\]`, 'ig');
      const value = user?.[this.pageBlockKeysMap[key]];
      result = result.replace(regexp, value);
    });

    return result;
  }

}
