<ng-container *ngIf="url; else noAvatarTmp">
  <ng-container *ngIf="isBase64; else backgroundImageTmp">
    <img 
      class="user-avatar user-avatar--img"
      [style.width.px]="size"
      [style.height.px]="size"
      [src]="url | async">
  </ng-container>

  <ng-template #backgroundImageTmp>
    <div 
      class="user-avatar"
      [style.width.px]="size"
      [style.height.px]="size"
      [cropImage]="url | async" 
      [disabled]="!crop"
      [additionalOptions]="{width: size, height: size, func: 'bound' }">
    </div>
  </ng-template>
</ng-container>

<ng-template #noAvatarTmp>
  <div 
    class="user-avatar user-avatar--initials"
    [class.dark-text]="isColorLight"
    [style.width.px]="size"
    [style.height.px]="size"
    [style.fontSize.px]="(size /100) * 32"
    [style.background]="color">
    <span>{{initials || '?'}}</span>
  </div>
</ng-template>
