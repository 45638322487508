import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { SessionStorageService } from './session-storage.service';
import { NetworkService } from './network.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedLibraryService } from './shared-library.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private sharedLibraryService: SharedLibraryService,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private storage: StorageMap,
    private readonly networkService: NetworkService,
    private readonly translateService: TranslateService
  ) { }

  public get baseUrl(): string {
    const lang = this.translateService.currentLang || 'en';
    const apiUrl = this.sharedLibraryService.environments.apiUrl;
    return lang === 'en' ? `${apiUrl}api/` : `${apiUrl}${lang}/api/`;
  }

  public getToken(): string {
    return this.sessionStorageService.getJwtTokenValue();
  }

  public getTokenHeader(httpHeaders: HttpHeaders): HttpHeaders {
    const token = this.getToken();
    if (token) {
      httpHeaders = httpHeaders.append('Authorization', `Bearer ${token}`);
    }
    return httpHeaders;
  }

  private buildHeaders(httpHeaders = new HttpHeaders()): HttpHeaders {
    if (!httpHeaders) {
      httpHeaders = new HttpHeaders();
    }

    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    httpHeaders = this.getTokenHeader(httpHeaders);
    return httpHeaders;
  }

  private buildHeadersMultiPart(): HttpHeaders {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = this.getTokenHeader(httpHeaders);
    return httpHeaders;
  }

  public head<T>(url: string, params = new HttpParams(), noHeaders = false): Observable<T> {
    return this.http.head<T>(this.baseUrl + url, { headers: !noHeaders ? this.buildHeaders() : undefined, params });
  }

  public get<T>(url: string, params = new HttpParams(), noHeaders = false, withCredentials = false): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, { headers: !noHeaders ? this.buildHeaders() : undefined, params, withCredentials }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getCore<T>(url: string, params = new HttpParams(), noHeaders = false, withCredentials = false): Observable<T> {
    return this.http.get<T>(this.sharedLibraryService.environments.apiCoreAppUrl + 'api' + url, { headers: !noHeaders ? this.buildHeaders() : undefined, params, withCredentials }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public postCore<T>(
    url: string, data: any, withCredentials: boolean = false, noHeaders = false, additionalHeaders = new HttpHeaders()
  ) {
    return this.http.post<T>(this.sharedLibraryService.environments.apiCoreAppUrl + 'api' + url, data, { headers: !noHeaders ? this.buildHeaders(additionalHeaders) : undefined, withCredentials })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public deleteCore<T>(url: string, params = new HttpParams()): Observable<T> {
    return this.http.delete<T>(this.sharedLibraryService.environments.apiCoreAppUrl + 'api' + url, { headers: this.buildHeaders(), params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getThirdParty<T>(url: string, params = new HttpParams(), noHeaders = false, withCredentials = false) {
    return this.http.get<T>(url, { headers: !noHeaders ? this.buildHeaders() : undefined, params, withCredentials }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getBlobFile(url: string): Observable<any> {
    return this.http.get(url, { headers: this.buildHeaders(), responseType: 'blob' }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public getFileAsArrayBuffer(fileSrc: string): Observable<any> {
    return this.http.get<any>(fileSrc, { responseType: 'arraybuffer' as any });
  }

  public post<T>(
    url: string, data: any, withCredentials: boolean = false, noHeaders = false, additionalHeaders = new HttpHeaders()
  ): Observable<T> {
    if (!this.networkService.isOnlineValue()) {
      this.storage.get('postPutRequestQueue').subscribe((res: any) => {
        const requests = res || [];
        requests.push({ url, data, withCredentials, noHeaders, additionalHeaders });
        this.storage.set('postPutRequestQueue', requests).subscribe();
      });

      // TODO: return predefined response for offline mode
      return of();
    }

    return this.http.post<T>(this.baseUrl + url, data, { headers: !noHeaders ? this.buildHeaders(additionalHeaders) : undefined, withCredentials })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public postThirdParty<T>(
    url: string, data: any, withCredentials: boolean = false, noHeaders = false, additionalHeaders = new HttpHeaders()
  ) {
    return this.http.post<T>(url, data, { headers: !noHeaders ? this.buildHeaders(additionalHeaders) : undefined, withCredentials })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public patch<T>(url: string, data: any): Observable<T> {
    return this.http.patch<T>(this.baseUrl + url, data, { headers: this.buildHeaders() }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public postMuiltiPart<T>(url: string, formData: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, formData, { headers: this.buildHeadersMultiPart() }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public put<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, data, { headers: this.buildHeaders() }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  public delete<T>(url: string, params = new HttpParams()): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, { headers: this.buildHeaders(), params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
