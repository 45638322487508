<div class="reward-modal">
  <div class="reward-modal__header">
    <div *ngIf="pages.length > 1" class="reward-modal__header--pager">
      <div *ngFor="let item of pages; index as i" [class.active]="currentPageIndex >= i" class="reward-modal__header--pager-page" (click)="selectPage(i)"></div>
    </div>
    <button class="button button--icon reward-modal__header--close" mat-icon-button mat-dialog-close>
      <lib-icon icon="x"></lib-icon>
    </button>
  </div>

  <ng-container *ngFor="let page of pages; index as i">
    <div [class.d-none]="currentPageIndex !== i" class="reward-modal__body">
      <ng-container [ngTemplateOutlet]="page.template" [ngTemplateOutletContext]="{data: page.data}"></ng-container>
    </div>
  </ng-container>

  <div class="reward-modal__footer">
    <div class="reward-modal__footer--buttons">
      <button *ngIf="pages[currentPageIndex]?.type === 'feedback'" mat-flat-button mat-dialog-close class="button button--large button--outline button--transparent">{{'COMMON.cancel' | translate}}</button>
      <button *ngIf="pages[currentPageIndex]?.type === 'points'" mat-flat-button class="button button--large button--outline button--transparent" (click)="navigateInNewTab('/leaderboard')">{{'LEADERBOARD.see_leaderboard' | translate}}</button>
      <button *ngIf="pages[currentPageIndex]?.type === 'badge'" mat-flat-button class="button button--large button--outline button--transparent" (click)="navigateInNewTab('/my-achievements')">{{'ACHIEVEMENTS.see_badges' | translate}}</button>
      <button *ngIf="pages[currentPageIndex]?.type === 'certificate'" mat-flat-button class="button button--large button--outline button--transparent" (click)="navigateInNewTab('/my-achievements')">{{'COMMON.view_certificate' | translate}}</button>
      <button mat-flat-button class="button button--large button--primary" [disabled]="isContinueLoading" (click)="nextPage()">
        <mat-spinner *ngIf="isContinueLoading" diameter="20"></mat-spinner> {{'COMMON.continue' | translate}}
      </button>
    </div>
  </div>
</div>

<ng-template #feedbackPageTemplate>
  <div class="reward-modal__page">
    <div class="reward-modal__page--header">
      <img *ngIf="feedbackSettings.banner; else imageFallbackTemplate" [cropImage]="feedbackSettings.banner | preSignedUrl | async">
    </div>
    <div class="reward-modal__page--body">
      <div *ngIf="feedbackSettings.text; else textFallbackTemplate" class="reward-modal__page--body-title" [innerHtml]="feedbackSettings.text | safeHtml"></div>
      <ng-container *ngIf="feedbackSettings.ratingEnabled">
        <p class="reward-modal__page--body-subtitle">{{'LESSON_FEEDBACK.give_us_your_feedback' | translate}}</p>
        <div class="reward-modal__page--star-list">
          <div *ngFor="let item of ratings" class="reward-modal__page--star" [class.active]="feedbackForm.get('rate').value >= item" (click)="setRating(item)">
            <lib-icon icon="star" [fill]="feedbackForm.get('rate').value >= item"></lib-icon>
          </div>
        </div>
      </ng-container>
      <div *ngIf="feedbackSettings.fields?.length" class="reward-modal__page--feedback">
        <button *ngIf="!formVisible" mat-flat-button class="button button--primary" (click)="showForm()">{{feedbackSettings.showFormButtonLabel}}</button>
  
        <div *ngIf="formVisible" class="lesson-feedback-modal__feedback" [formGroup]="feedbackForm">
          <div *ngFor="let field of feedbackSettings.fields" class="reward-modal__page--feedback--field-wrapper">
            <div class="reward-modal__page--feedback--label">{{field.displayName}}</div>
            <ng-container [ngSwitch]="field.type">
              <mat-form-field *ngSwitchCase="fieldTypes.FORMATTED_TEXT" class="reward-modal__page--feedback--field form-field-textarea" appearance="outline">
                <textarea 
                  class="reward-modal__page--feedback--textarea"
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="1"
                  [formControlName]="field.id"
                  [placeholder]="'COMMON.type_here' | translate ">
                </textarea>
              </mat-form-field>
              <mat-form-field *ngSwitchDefault class="reward-modal__page--feedback--field" appearance="outline">
                <input matInput [formControlName]="field.id">
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #imageFallbackTemplate>
    <lib-icon icon="student" [duotone]="true" [size]="100"></lib-icon>
  </ng-template>
  
  <ng-template #textFallbackTemplate>
    <div class="reward-modal__page--body-title">{{'LESSON_FEEDBACK.well_done' | translate}} {{'LESSON_FEEDBACK.you_finished_this_lesson' | translate}}</div>
  </ng-template>
</ng-template>

<ng-template #pointsPageTemplate let-data="data">
  <div class="reward-modal__page">
    <div class="reward-modal__page--header">
      <lib-icon icon="trophy" [duotone]="true" [size]="100"></lib-icon>
    </div>
    <div class="reward-modal__page--body">
      <div class="reward-modal__page--body-title" [innerHtml]="'LESSON_FEEDBACK.earned_leaderboard_points' | translate: {count: ('<span class=\'primary-color\'>'+ data?.points +'</span>')}"></div>
      <div class="reward-modal__page--body-subtitle">{{'LEADERBOARD.you_are_now_on_position' | translate:{position: data?.currentPosition} }}</div>
    </div>
  </div>
</ng-template>

<ng-template #badgePageTemplate let-data="data">
  <div class="reward-modal__page">
    <div class="reward-modal__page--header">
      <img 
        *ngIf="data.mediaImage; else placeholderImageTemplate" 
        [cropImage]="data.mediaImage | preSignedUrl | async" 
        [additionalOptions]="{width: 150, height: 150, func: 'bound'}" 
        alt="achievement">
      <ng-template #placeholderImageTemplate>
        <img src="assets/images/achievement.png" alt="achievement">
      </ng-template>
    </div>
    <div class="reward-modal__page--body">
      <div class="reward-modal__page--body-title" [innerHtml]="'LESSON_FEEDBACK.you_earned' | translate: {title: ('<span class=\'primary-color\'>'+ data?.title +'</span>')}"></div>
      <div class="reward-modal__page--body-subtitle">{{'COMMON.congratulations' | translate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #certificatePageTemplate let-data="data">
  <div class="reward-modal__page">
    <div class="reward-modal__page--header">
      <div
        class="reward-modal__page--header-certificate"
        [cropImage]="data?.certifications[0]?.mediaImage | preSignedUrl | async" 
        [additionalOptions]="{width: 400}">
      </div>
      <ng-template #placeholderImageTemplate>
        <img src="assets/images/achievement.png" alt="achievement">
      </ng-template>
    </div>
    <div class="reward-modal__page--body">
      <div class="reward-modal__page--body-title" [innerHtml]="'LESSON_FEEDBACK.certificate_earned' | translate"></div>
      <div class="reward-modal__page--body-subtitle" [innerHtml]="'LESSON_FEEDBACK.certificate_received_for_completing' | translate:{certificate: ('<strong>'+ data?.certifications[0]?.title +'</string>'), content: ('<strong>'+ data?.title +'</strong>')}"></div>
    </div>
  </div>
</ng-template>
