import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationDeliveryType } from '@app/model/enums/notification-delivery-type.enum';
import { NotificationDelivery } from '@app/model/notification-delivery';
import { NotificationApiService } from '@app/services/api/notification-api.service';
import { NavigateService } from '@app/services/navigate.service';
import { NotificationService } from '@app/services/notification.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { fromEvent, Subject, Subscription, timer } from 'rxjs';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notifications-list-dialog',
  templateUrl: './notifications-list-dialog.component.html',
  styleUrls: ['./notifications-list-dialog.component.scss']
})
export class NotificationsListDialogComponent implements OnInit, OnDestroy {
  @ViewChild(NgScrollbar, { static: false }) public set scrollbarRef(value: NgScrollbar) {
    if (!value || value === this._scrollbarRef) {
      return;
    }

    this._scrollbarRef = value;
    this.addScrollbarSubscription();
  }

  public get scrollbarRef() {
    return this._scrollbarRef;
  }

  public notifications: Array<NotificationDelivery> = [];
  public hasScroll = false;

  public total: number;

  public placeholders = new Array(4).fill(null);

  public hasNextNotifications = false;
  public isLoading = false;
  private currentPage = 1;
  private unsubscribe: Subject<void> = new Subject();
  private scrollSub: Subscription;
  private _scrollbarRef: NgScrollbar;

  private initializedData =  false;
  
  constructor(
    private readonly notificationApiService: NotificationApiService,
    private readonly navigationService: NavigateService,
    private readonly elementRef: ElementRef,
    private readonly notificationService: NotificationService) { }

  ngOnInit() {
    this.addSubscriptionForNotifications();
    this.listenForOutsideClicks();

    this.loadNotifications();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public notificationNavigate(item: NotificationDelivery): void {
    this.notificationService.markNotificationAsSeen(item, NotificationDeliveryType.REGULAR);
    this.navigationService.navigateByNavigationRouteLink(item.link);
  }

  public closeDialog(): void {
    this.notificationService.closeListDialog();
  }

  public trackByFn(_index: number, item: NotificationDelivery) {
    return item['@id'];
  }

  public scrollUpdated(): void {
    this.hasScroll = this.scrollbarRef?.state?.isVerticallyScrollable;
  }

  private addScrollbarSubscription(): void {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }

    this.scrollSub = this.scrollbarRef.scrolled
      .pipe(
        takeUntil(this.unsubscribe),
        filter(() => !this.isLoading && this.hasNextNotifications),
        filter((event: Event) => {
          const targetElement = event.target as HTMLElement;
          return targetElement.scrollHeight - targetElement.offsetHeight - targetElement.scrollTop < 100
        })
      )
      .subscribe(() => {
        this.loadNotifications(this.currentPage + 1);
      });
  }

  private loadNotifications(page = 1): void {
    this.isLoading = true;

    this.notificationApiService.getNotifications(page)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(data => {
        this.currentPage = page;
        this.hasNextNotifications = !!data?.['hydra:view']?.['hydra:next'];

        this.prependNotificationsToList(data?.['hydra:member']);
        this.total = data?.['hydra:totalItems'];
        this.initializedData = true;
      });
  }

  private prependNotificationsToList(items: NotificationDelivery[], prepend = false): void {
    if (!items) {
      return;
    }
  
    const uniqueNotifications = items.filter(item => !this.notifications.some(notification => notification.notificationId === item.notificationId));

    if (!prepend) {
      this.notifications.push(...uniqueNotifications)
      return;
    }

    this.notifications.unshift(...uniqueNotifications);
  }

  private listenForOutsideClicks(): void {
    timer(100)
      .pipe(
        switchMap(() => fromEvent(document, 'click')),
        takeUntil(this.unsubscribe),
        filter((event: MouseEvent) => {
          return !this.elementRef.nativeElement.contains(event.target);
        })
      )
      .subscribe(() => {
        this.closeDialog();
      });
  }

  private addSubscriptionForNotifications(): void {
    this.notificationService.notifications
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        if (!this.initializedData) {
          return;
        }
        
        this.prependNotificationsToList(data, true);
      });
  }
}
