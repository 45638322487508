import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ProviderType } from 'library-explorer';
import { HttpService, FileSystemService } from 'library-explorer';
import { LoadingToastService } from '../loading-toast.service';
import { Capacitor } from '@capacitor/core';
import { Directory, Encoding } from '@capacitor/filesystem';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private readonly loadingToastrService: LoadingToastService,
    private readonly toastr: ToastrService,
    private fileSystemService: FileSystemService,
    private httpService: HttpService) { }

  public downloadPDF(url: string): Observable<any> {
    return this.httpService.getBlobFile(url);
  }

  public downloadFile(url: string, provider: ProviderType, fileName: string, toastStart: boolean = true): Observable<void> {
    const decodedUrl = decodeURIComponent(url);

    if (Capacitor.isNativePlatform()) {
      this.downloadAndSaveFile(decodedUrl, fileName);
      return of();
    }

    if (provider === ProviderType.AWS) {
      if(toastStart) this.loadingToastrService.start();
      return this.loadFileAsArrayBuffer(decodedUrl, fileName)
        .pipe(
          finalize(() => this.loadingToastrService.stop())
        )
    }

    window.open(decodedUrl, '_blank');
    return of();
  }

  private loadFileAsArrayBuffer(url: string, fileName: string): Observable<void> {
    return this.getFileAsArrayBuffer(url)
      .pipe(
        tap((data) => {
          const blob = new Blob([data]);
          const blobUrl = URL.createObjectURL(blob);
          this.download(blobUrl, fileName);
        })
      );
  }

  private download(url: string, fileName: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    link.click();
  }

  public getFileAsArrayBuffer(fileSrc: string): Observable<any> {
    return this.httpService.getFileAsArrayBuffer(fileSrc);
  }

  private async downloadAndSaveFile(url: string, fileName: string): Promise<void> {
    this.loadingToastrService.start();

    try {
      const data = await this.fileSystemService.downloadBlob(url, (progress: number) => {
        if (progress >= 100) {
          this.loadingToastrService.stop();
        }
      });

      const fileUri = await this.fileSystemService.writeBlobFile(fileName, new Blob([data]), true, Directory.Documents, Encoding.UTF8, true);
      if (fileUri) {
        this.toastr.success(`Download completed: ${fileName}`)
      }
    } catch (error) {
      this.toastr.error('Error downloading and saving file.')
    } finally {
      this.loadingToastrService.stop();
    }
  }

}
