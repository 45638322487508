import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ImageModel, SettingsUserAvatarType, ProfileUserDTO, FieldName, ProviderType, EntityTypeId, BundleType } from '../../models';
import { BaseSettingsGetService, MediaPresignApiService } from '../../services';
import { SETTINGS_SERVICE } from '../../constants';
import Color from 'color';

@Component({
  selector: 'lib-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnDestroy {
  @Input() public crop = true;
  @Input() public size = 50;

  @Input() public set user(value: Partial<ProfileUserDTO> & { fullname?: string, metadata?: string }) {
    if (!value) {
      return;
    }
  
    if (value.metadata) {
      const meta = this.isJson(value.metadata) ? JSON.parse(value.metadata) : value.metadata;

      if (meta.mediaImage) {
        Object.assign(value, { mediaImage: { key: meta.mediaImage }});
      }

      if (meta.mediaAvatar) {
        Object.assign(value, { mediaAvatar: { key: meta.mediaAvatar }});
      }
    }

    this._user = value;
    this.setUserAvatar();
  }

  public get user() {
    return this._user;
  }

  public url: Observable<string>;
  public initials: string;

  public isColorLight = false;
  public color: string;

  public isBase64 = false;

  private _user: Partial<ProfileUserDTO & { fullname?: string, metadata?: string }>;
  private avatarMode: SettingsUserAvatarType = SettingsUserAvatarType.DISABLED;
  private avatars = [];
  private unsubscribe: Subject<void> = new Subject();

  private initialized = false;

  private colors = [];

  constructor(
    private readonly mediaPresignApiService: MediaPresignApiService,
    @Inject(SETTINGS_SERVICE) private readonly settingsService: BaseSettingsGetService) {  }

  public ngOnInit(): void {
    this.settingsService.getSettings()
      .pipe(
        filter(data => !!data),
        takeUntil(this.unsubscribe)
      )
      .subscribe(data => {
        this.colors = data.profile.avatarsColors;
        this.avatars = data.profile.avatars || [];
        this.avatarMode = data.profile.avatarMode || SettingsUserAvatarType.DISABLED;
        this.initialized = true;

        this.setUserAvatar();
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setUserAvatar(): void {
    if (!this.user || !this.initialized) {
      return;
    }

    const avatar = this.getProfileAvatar();
    this.setColorForAvatar();
    const profileImage = this.user.mediaImage;

    switch (this.avatarMode) {
      case SettingsUserAvatarType.AVATAR_ONLY:
        this.setAvatar(avatar, FieldName.MEDIA_AVATAR);

        break;
      case SettingsUserAvatarType.PHOTO_ONLY:
        this.setAvatar(profileImage, FieldName.MEDIA_IMAGE);
        break;
      case SettingsUserAvatarType.AVATAR_AND_PHOTO:
        if (profileImage) {
          this.setAvatar(profileImage, FieldName.MEDIA_IMAGE);
        } else {
          this.setAvatar(avatar, FieldName.MEDIA_AVATAR);
        }
        break;
      default:
        this.url = null;
        return;
    }
  }

  private setColorForAvatar(): void {
    const parts = this._user.fullname && this._user.fullname.split(' ') || [];
    const firstName = this._user.name || parts[0] || '';
    const lastName = this._user.lastName || parts.reverse()[0] || '';

    this.initials = `${firstName.charAt(0).toLocaleUpperCase()}${lastName.charAt(0).toLocaleUpperCase()}`;

    const colorIndex = this.getLettersCodeCombination(firstName) + this.getLettersCodeCombination(lastName);
    this.color = this.colors?.[colorIndex % this.colors.length];

    this.isColorLight = this.color && Color(this.color).isLight();
  }

  private setAvatar(image: ImageModel, fieldName: FieldName): void {
    if (image && image.provider === ProviderType.AWS) {
      this.url = this.mediaPresignApiService.getPreSignedUrl(EntityTypeId.USER, BundleType.USER, fieldName, image.key);
      return;
    }

    this.url = image && image.url ? of(image.url) : null;
  }

  private getProfileAvatar(): ImageModel {
    const avatar = this.avatars && this.avatars.find(item => {
      return (this.user.mediaAvatar && item.key === this.user.mediaAvatar.key) || item.uri === this.user.avatarUrl;
    });

    return avatar;
  }

  private isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }


  private getLettersCodeCombination(value: string): number {
    return value ? value.charCodeAt(0) + value.charCodeAt(value.length - 1) : 0;
  }
}
